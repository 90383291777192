/* eslint-disable react/no-unknown-property */

import Breadcrumb from "../../components_helpers/breadcrumb"
import { formatarValorEmDinheiro, formatDateNew } from "../../helpers/FormatData"
import moment from "moment"

const Online = (data) => {
  const obj = data.data

  const calcularIdade = (dataNascimento) => {
    if (!!dataNascimento && dataNascimento != null) {
      const partesData = dataNascimento.split('-');
      const diaNasc = parseInt(partesData[2], 10);
      const mesNasc = parseInt(partesData[1], 10) - 1;
      const anoNasc = parseInt(partesData[0], 10);
      const hoje = new Date();
      const dataNasc = new Date(anoNasc, mesNasc, diaNasc);
      let idade = hoje.getFullYear() - dataNasc.getFullYear();
      const mesAtual = hoje.getMonth();
      const diaAtual = hoje.getDate();

      if (
        mesAtual < mesNasc ||
        (mesAtual === mesNasc && diaAtual < diaNasc)
      ) {
        idade--;
      }

      return `(${idade} anos)`;
    }

    return ''
  }

  const priLetraMaiuscula = (str) => {
    const separador = str.includes('-') ? '-' : ' ';

    return str.split(separador).map(palavra => {
      return palavra.charAt(0).toUpperCase() + palavra.slice(1).toLowerCase();
    }).join(' ');
  }


  // useEffect(() => {
  //     getBeneficioOff();
  // })


  if (!obj) {
    return (<>
      <p>aguarde estamos carregado....</p>
    </>)
  }

  const handlePrintToPDF = () => {
    const element0 = document.querySelector('header');
    const element1 = document.querySelector('.pagetitle');
    const element2 = document.querySelector('div.position-fixed');

    element0.style.setProperty('display', 'none', 'important');
    element1.style.setProperty('display', 'none', 'important');
    element2.style.setProperty('display', 'none', 'important');

    // Chamar a função de impressão
    window.print();

    element0.style.removeProperty('display');
    element1.style.removeProperty('display');
    element2.style.removeProperty('display');
  };

  const dados = obj?.dados
  console.log(dados)
  const especie = obj?.dados?.especie?.codigo === null ? obj?.dados?.especie?.descricao : `${obj?.dados?.especie?.descricao}`
  const nascimento = formatDateNew(obj?.dados?.data_nascimento, 'Nascimento')
  const meioPagto = priLetraMaiuscula(obj?.dados?.meioPagamento)
  console.log(obj?.dados?.meioPagamento)
  const agencia = obj?.dados?.agencia
  const contratos = obj?.contratosEmprestimo
  const cartaoRmc = obj?.contratosRMC
  const cartaoRcc = obj?.contratosRCC


  const utilizada = obj?.dados?.margemUtilizadaEmprestimo
  const margemExtrapolada = ((obj?.dados?.valorBase * 0.35) - utilizada) > 0 ? 0 : (obj?.dados?.valorBase * 0.35) - utilizada

  return (<>
    <style type="text/css" media="print">
      {`
    @page { size: landscape; }
    
    .page-break {
      page-break-before: always;
    }

    .avoid-page-break {
      page-break-inside: avoid;
    }
    
    .mbox2 {
      margin-top: 2em;
    }
  `}
    </style>

    <Breadcrumb name={`CONSULTA ${dados.beneficio}`} button={[
      {
        name: 'Imprimir Consulta',
        colorBtn: 'primary',
        click: handlePrintToPDF,
        value: data,
        view: true
      }
    ]} />

    <section className="section contact">
      <div className="row gy-4">
        <div className="col-xl-12">
          <div className="rowk">
            <div style={{ textAlign: 'center' }} >
              <h4>HISTÓRICO DE</h4><h5><b>EMPRÉSTIMO CONSIGNADO</b></h5>
              <p style={{ width: '100%', padding: '1.5px', backgroundColor: '#ccc' }}></p>
              <p style={{ fontSize: '1.5em' }}><b>{obj?.dados?.nome || 'Não Informado'}</b></p>
            </div>

            <div className="info-box card">
              {/* <i className="ri-home-5-fill"></i> */}
              <h3>Benefício</h3>
              <br />

              <div className="row col-12">
                <table className="table" style={{ fontSize: '0.85em', borderColor: '#fff', marginLeft: '0.5em' }}>
                  <tbody>
                    <tr>
                      <td colSpan="2" style={{ padding: '3px', color: 'blue', fontWeight: 'bold' }}><b>{especie || 'Não Informado'}</b></td>
                    </tr>

                    <tr>
                      <td className="col-6" style={{ padding: '3px' }}><b>Nº Benefício:</b> {obj?.dados?.beneficio || 'Não Informado'}</td>
                      <td className="col-6" style={{ padding: '3px' }}>{obj?.dados?.possuiProcurador ? "Possui procurador" : "Não possui procurador"}</td>
                    </tr>

                    <tr>
                      <td className="col-6" style={{ padding: '3px' }}><b>CPF:</b> {obj?.dados?.cpf || 'Não Informado'}</td>
                      <td className="col-6" style={{ padding: '3px' }}>{obj?.dados?.possuiRepresentante ? "Possui procurador" : "Não possui representante legal"}</td>
                    </tr>

                    <tr>
                      <td className="col-6" style={{ padding: '3px' }}><b>Data Nasc.:</b> {nascimento || 'Não Informado'} <span style={{ marginLeft: '0.5em', fontWeight: 'bold  ' }}>{calcularIdade(obj?.dados?.data_nascimento)}</span></td>
                      <td className="col-6" style={{ padding: '3px' }}>{obj?.dados?.pensaoAlimenticia ? "É pensão alimentícia" : "Não é pensão alimentícia"}</td>
                    </tr>

                    <tr>
                      <td className="col-6" style={{ padding: '3px' }}><b>Situação:</b> {obj?.dados?.situacao || 'Não Informado'}</td>
                      <td className="col-6" style={{ padding: '3px' }}>{obj?.dados?.bloqueioEmprestismo ? "Bloqueado para Empréstimo" : "Liberado para empréstimo"}</td>
                    </tr>

                    <tr>
                      <td className="col-6" style={{ padding: '3px' }}><b>Pago em:</b> {obj?.dados?.banco?.codigo || 'Não Informado'} - {obj?.dados?.banco?.descricao || 'Não Informado'}</td>
                      <td className="col-6" style={{ padding: '3px' }}><b>{obj?.dados?.elegivelEmprestimo ? "Elegível para empréstimos" : "Não elegível para empréstimos"}</b></td>
                    </tr>

                    <tr>
                      <td className="col-6" style={{ padding: '3px' }}><b>Meio:</b> {meioPagto || 'Não Informado'}</td>
                      <td className="col-6" style={{ padding: '3px' }}></td>
                    </tr>

                    <tr>
                      <td className="col-6" style={{ padding: '3px' }}><b>Agência:</b> {agencia || 'Não Informado'}</td>
                      <td className="col-6" style={{ padding: '3px' }}></td>
                    </tr>

                    <tr>
                      <td className="col-6" style={{ padding: '3px' }}><b>Conta Corrente:</b> {obj?.dados?.conta || 'Não Informado'}</td>
                      <td className="col-6" style={{ padding: '3px' }}></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="row avoid-page-break">
            <div className="info-box card mbox2">
              {/* <i className="ri-home-5-fill"></i> */}
              <h3>Margem para Empréstimo/Cartão e Resumo Financeiro</h3>
              <br />
              <div className="row col-6">
                <table className="table table-bordered" style={{ borderColor: '#000' }}>
                  <tbody>
                    <tr>
                      <td colspan="2" style={{ background: '#eee', fontWeight: 'bold', textAlign: 'center' }}>VALORES DO BENEFÍCIO</td>
                    </tr>
                    <tr>
                      <td style={{ background: '#eee', fontWeight: 'bold' }}>BASE DE CÁLCULO</td>
                      <td style={{ textAlign: 'right' }}>{formatarValorEmDinheiro(obj?.dados?.valorBase)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="row col-12">
                <table className="table table-bordered" style={{ borderColor: '#000' }}>
                  <tbody>
                    <tr>
                      <td colSpan="4" style={{ background: '#eee', fontWeight: 'bold', textAlign: 'center' }}>VALORES POR MODALIDADE</td>
                    </tr>
                    <tr>
                      <td style={{ background: '#eee', fontWeight: 'bold' }}></td>
                      <td style={{ background: '#eee', fontWeight: 'bold' }}>EMPRESTIMOS</td>
                      <td style={{ background: '#eee', fontWeight: 'bold' }}>RMC</td>
                      <td style={{ background: '#eee', fontWeight: 'bold' }}>RCC</td>
                    </tr>
                    <tr>
                      <td style={{ background: '#eee', fontWeight: 'bold' }}>MARGEM CONSIGNÁVEL*</td>
                      <td style={{ textAlign: 'right' }}>{formatarValorEmDinheiro((obj?.dados?.valorBase * 0.35))}</td>
                      <td style={{ textAlign: 'right' }}>{formatarValorEmDinheiro((obj?.dados?.valorBase * 0.05))}</td>
                      <td style={{ textAlign: 'right' }}>{formatarValorEmDinheiro((obj?.dados?.valorBase * 0.05))}</td>
                    </tr>
                    <tr>
                      <td style={{ background: '#eee', fontWeight: 'bold' }}>MARGEM UTILIZADA</td>
                      <td style={{ textAlign: 'right' }}>{formatarValorEmDinheiro(utilizada)}</td>
                      <td style={{ textAlign: 'right' }}>{formatarValorEmDinheiro(obj?.dados.margemReservadaRMC)}</td>
                      <td style={{ textAlign: 'right' }}>{formatarValorEmDinheiro(obj?.dados.margemReservadaRCC)}</td>
                    </tr>
                    <tr>
                      <td style={{ background: '#eee', fontWeight: 'bold' }}>MARGEM RESERVADA**</td>
                      <td style={{ textAlign: 'right' }}>R$ 0,00</td>
                      <td style={{ textAlign: 'center' }}>-</td>
                      <td style={{ textAlign: 'center' }}>-</td>
                    </tr>
                    <tr>
                      <td style={{ background: '#eee', fontWeight: 'bold' }}>MARGEM DISPONÍVEL</td>
                      <td style={{ textAlign: 'right' }}>{formatarValorEmDinheiro(obj?.dados?.margemDisponivelEmprestimo < 0 ? 0 : obj?.dados?.margemDisponivelEmprestimo)}</td>
                      <td style={{ textAlign: 'right' }}>{formatarValorEmDinheiro(obj?.dados.margemDisponivelRMC)}</td>
                      <td style={{ textAlign: 'right' }}>{formatarValorEmDinheiro(obj?.dados.margemDisponivelRCC)}</td>
                    </tr>
                    <tr>
                      <td style={{ background: '#eee', fontWeight: 'bold' }}>MARGEM EXTRAPOLADA***</td>
                      <td style={{ textAlign: 'right' }}>{formatarValorEmDinheiro(margemExtrapolada)}</td>
                      <td style={{ textAlign: 'right' }}>R$ 0,00</td>
                      <td style={{ textAlign: 'right' }}>R$ 0,00</td>
                    </tr>
                  </tbody>
                </table>
                <div>
                  <p style={{ fontSize: '0.775em', lineHeight: '1.3em' }}>
                    * Para benefícios das espécies, 18, 87 e 88 a margem consignável representa 30% da base de cálculo para empréstimos e 5% para cartão, podendo optar por somente uma das modalidades RMC ou RCC. Para as demais espécies, a margem consignável atual representa 35% da base de cálculo para empréstimos, 10% para cartão, sendo 5% para RMC e 5% para RCC<br />
                    ** O valor da margem reservada está incluído no valor da margem utilizada.<br />
                    *** A margem extrapolada representa o valor que excedeu a margem disponível da modalidade ou o máximo de comprometimento do benefício, que pode ocorrer em situações específicas como a redução da renda do benefício ou a alteração legal da margem consignável de empréstimos e cartões.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row avoid-page-break">
            <div className="info-box card mbox2">
              <div className="d-none" id="dividerPrint" style={{ height: '3rem' }} > </div>
              <h3>EMPRÉSTIMOS BANCÁRIOS</h3>
              <br />
              <div className="row">
                <table className="table table-bordered" style={{ borderColor: '#000' }}>
                  <tbody>
                    <tr>
                      <td colspan="15" style={{ backgroundColor: '#eee', fontSize: '1.3em', color: '#aaa' }}>CONTRATOS ATIVOS E SUSPENSOS*</td>
                    </tr>
                    <tr style={{ textAlign: 'center', fontSize: '0.68em' }}>
                      <td style={{ backgroundColor: '#eee', fontWeight: 'bold', verticalAlign: 'bottom' }}><b>CONTRATO</b></td>
                      <td style={{ backgroundColor: '#eee', fontWeight: 'bold', verticalAlign: 'bottom' }}><b>BANCO</b></td>
                      <td style={{ backgroundColor: '#eee', fontWeight: 'bold', verticalAlign: 'bottom' }}><b>SITUAÇÃO</b></td>
                      <td style={{ backgroundColor: '#eee', fontWeight: 'bold', verticalAlign: 'bottom' }}><b>DT. INCLUSÃO</b></td>
                      <td style={{ backgroundColor: '#eee', fontWeight: 'bold', verticalAlign: 'bottom' }}><b>INÍCIO DESC.</b></td>
                      <td style={{ backgroundColor: '#eee', fontWeight: 'bold', verticalAlign: 'bottom' }}><b>FIM DESC.</b></td>
                      <td style={{ backgroundColor: '#eee', fontWeight: 'bold', verticalAlign: 'bottom' }}><b>QTD PARCELAS</b></td>
                      <td style={{ backgroundColor: '#eee', fontWeight: 'bold', verticalAlign: 'bottom' }}><b>PARCELA</b></td>
                      <td style={{ backgroundColor: '#eee', fontWeight: 'bold', verticalAlign: 'bottom' }}><b>TAXA</b></td>
                      <td style={{ backgroundColor: '#eee', fontWeight: 'bold', verticalAlign: 'bottom' }}><b>CT MENSAL</b></td>
                      <td style={{ backgroundColor: '#eee', fontWeight: 'bold', verticalAlign: 'bottom' }}><b>EMPRESTADO</b></td>
                      <td style={{ backgroundColor: '#eee', fontWeight: 'bold', verticalAlign: 'bottom' }}><b>LIBERADO</b></td>
                      <td style={{ backgroundColor: '#eee', fontWeight: 'bold', verticalAlign: 'bottom' }}><b>SALDO DEV.</b></td>
                    </tr>

                    {(contratos && contratos.length > 0 && contratos[0] !== null) ? contratos.map((e) => {
                      let diferencaMeses = 0
                      if (e?.competenciaInicioDesconto) {
                        const data = moment(e?.competenciaInicioDesconto, 'YYYY-MM-DD');
                        const data1 = data.format('YYYY-MM-DD');
                        const data2 = moment();
                        diferencaMeses = data2.diff(data1, 'months');
                      }

                      let danger = ''
                      if (e?.valorLiberado <= 0) {
                        danger = 'text-danger fw-bold'
                      }


                      return (<>
                        <tr style={{ textAlign: 'center', fontSize: '0.68em' }}>
                          <td style={{ verticalAlign: 'middle', fontSize: '0.85em' }}>{e?.contrato || "Não encontrado"}</td>
                          <td style={{ verticalAlign: 'middle' }}>{e?.banco?.codigo} - {e?.banco?.descricao}</td>
                          <td style={{ verticalAlign: 'middle' }}>{e?.situacao}</td>
                          <td style={{ verticalAlign: 'middle' }}>{e?.dataInclusao ? formatDateNew(e?.dataInclusao, 'dataInclusao_emprestimos') : null}</td>
                          <td style={{ verticalAlign: 'middle' }}>{e?.competenciaInicioDesconto ? formatDateNew(e?.competenciaInicioDesconto.toString()) : null}</td>
                          <td style={{ verticalAlign: 'middle' }}>{e?.competenciaFimDesconto ? formatDateNew(e?.competenciaFimDesconto.toString()) : null}</td>
                          <td style={{ verticalAlign: 'middle' }}>{diferencaMeses} / {e?.quantidadeParcelas}</td>
                          <td style={{ verticalAlign: 'middle' }}>{e?.valorParcela ? formatarValorEmDinheiro(e?.valorParcela) : ''}</td>
                          <td className={danger} style={{ verticalAlign: 'middle' }}>{e?.taxaMensal > 0 ? e?.taxaMensal.toFixed(2) + "%" : ''}</td>
                          <td className={danger} style={{ verticalAlign: 'middle' }}>{e?.cetMensal > 0 ? e?.cetMensal.toFixed(2) + "%" : ''}</td>
                          <td style={{ verticalAlign: 'middle' }}>{e?.valorEmprestado ? formatarValorEmDinheiro(e?.valorEmprestado) : ''}</td>
                          <td style={{ verticalAlign: 'middle' }}>{e?.valorLiberado ? formatarValorEmDinheiro(e?.valorLiberado) : ''}</td>
                          <td className={danger} style={{ verticalAlign: 'middle' }}>{e?.saldoDevedor ? formatarValorEmDinheiro(e?.saldoDevedor) : ''}</td>
                        </tr>
                      </>)
                    }) : (<>
                      <tr style={{ textAlign: 'center' }} >
                        <td colspan="15">Não existem dados</td>
                      </tr>
                    </>)}
                  </tbody>
                </table>
                <div>
                  <p className="text-danger fw-bold h6">*** Os valores em vermelhos são dados aproximados calculados para ajudar o Corban ***</p>
                  {/* <p style={{ fontSize: '0.775em', lineHeight: '1.3em' }}><b>O Saldo devedor só é calculado quando existe o valor LIBERADO e é um valor ***APROXÍMADO***</b></p> */}
                  <p style={{ fontSize: '0.775em', lineHeight: '1.3em' }}>*Contratos que comprometem a margem consignável.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="row avoid-page-break">
            <div className="info-box card mbox2">
              {/* <i className="ri-home-5-fill"></i> */}
              <h3>CARTÃO DE CRÉDITO</h3>
              <br />

              <div className="row">
                <table className="table table-bordered" style={{ borderColor: '#000' }}>
                  <tbody>
                    <tr style={{ textAlign: 'center' }}>
                      <td colspan="7" style={{ backgroundColor: '#eee', fontWeight: 'bold' }}><b>CARTÃO DE CRÉDITO - RMC</b></td>
                    </tr>
                    <tr>
                      <td colspan="7" style={{ backgroundColor: '#eee', fontSize: '1.3em', color: '#aaa' }}>CONTRATOS ATIVOS E SUSPENSOS*</td>
                    </tr>
                    <tr style={{ textAlign: 'center', fontSize: '0.68em' }}>
                      <td style={{ backgroundColor: '#eee', fontWeight: 'bold', verticalAlign: 'bottom' }}><b>CONTRATO</b></td>
                      <td style={{ backgroundColor: '#eee', fontWeight: 'bold', verticalAlign: 'bottom' }}><b>BANCO</b></td>
                      <td style={{ backgroundColor: '#eee', fontWeight: 'bold', verticalAlign: 'bottom' }}><b>SITUAÇÃO</b></td>
                      <td style={{ backgroundColor: '#eee', fontWeight: 'bold', verticalAlign: 'bottom' }}><b>ORIGEM DA AVERBAÇÃO</b></td>
                      <td style={{ backgroundColor: '#eee', fontWeight: 'bold', verticalAlign: 'bottom' }}><b>DATA INCLUSÃO</b></td>
                      <td style={{ backgroundColor: '#eee', fontWeight: 'bold', verticalAlign: 'bottom' }}><b>LIMITE CARTÃO</b></td>
                      <td style={{ backgroundColor: '#eee', fontWeight: 'bold', verticalAlign: 'bottom' }}><b>RESERVADO</b></td>
                    </tr>

                    {(cartaoRmc && cartaoRmc.length > 0 && cartaoRcc[0] !== null) ? cartaoRmc.map((e) => {
                      return (<>
                        <tr style={{ textAlign: 'center', fontSize: '0.68em' }}>
                          <td style={{ verticalAlign: 'middle', fontSize: '0.85em' }}>{e?.contrato}</td>
                          <td style={{ verticalAlign: 'middle' }}>{e?.banco?.codigo} - {e?.banco?.descricao}</td>
                          <td style={{ verticalAlign: 'middle' }}>{e?.situacao}</td>
                          <td style={{ verticalAlign: 'middle' }}>Averbação nova</td>
                          <td style={{ verticalAlign: 'middle' }}>{e?.dataInclusao ? formatDateNew(e?.dataInclusao) : null}</td>
                          <td style={{ verticalAlign: 'middle' }}>{e?.valorLimiteCartao ? formatarValorEmDinheiro(e?.valorLimiteCartao) : null}</td>
                          <td style={{ verticalAlign: 'middle' }}>{e?.valorReservado ? formatarValorEmDinheiro(e?.valorReservado) : null}</td>
                        </tr>
                      </>)
                    }) : (<>
                      <tr style={{ textAlign: 'center' }} >
                        <td colspan="7">Não existem dados</td>
                      </tr>
                    </>)}
                  </tbody>
                </table>
                <div>
                  <p style={{ fontSize: '0.775em', lineHeight: '1.3em', marginBottom: '2.5em' }}>
                    *Contratos que comprometem a margem consignável.
                  </p>
                </div>
              </div>

              <div className="row">
                <table className="table table-bordered" style={{ borderColor: '#000' }}>
                  <tbody>
                    <tr style={{ textAlign: 'center' }}>
                      <td colspan="7" style={{ backgroundColor: '#eee', fontWeight: 'bold' }}><b>CARTÃO DE CRÉDITO - RCC</b></td>
                    </tr>
                    <tr>
                      <td colspan="7" style={{ backgroundColor: '#eee', fontSize: '1.3em', color: '#aaa' }}>CONTRATOS ATIVOS E SUSPENSOS*</td>
                    </tr>
                    <tr style={{ textAlign: 'center', fontSize: '0.68em' }}>
                      <td style={{ backgroundColor: '#eee', fontWeight: 'bold', verticalAlign: 'bottom' }}><b>CONTRATO</b></td>
                      <td style={{ backgroundColor: '#eee', fontWeight: 'bold', verticalAlign: 'bottom' }}><b>BANCO</b></td>
                      <td style={{ backgroundColor: '#eee', fontWeight: 'bold', verticalAlign: 'bottom' }}><b>SITUAÇÃO</b></td>
                      <td style={{ backgroundColor: '#eee', fontWeight: 'bold', verticalAlign: 'bottom' }}><b>ORIGEM DA AVERBAÇÃO</b></td>
                      <td style={{ backgroundColor: '#eee', fontWeight: 'bold', verticalAlign: 'bottom' }}><b>DATA INCLUSÃO</b></td>
                      <td style={{ backgroundColor: '#eee', fontWeight: 'bold', verticalAlign: 'bottom' }}><b>LIMITE CARTÃO</b></td>
                      <td style={{ backgroundColor: '#eee', fontWeight: 'bold', verticalAlign: 'bottom' }}><b>RESERVADO</b></td>
                    </tr>

                    {(cartaoRcc && cartaoRcc.length > 0 && cartaoRcc[0] !== null) ? cartaoRcc.map((e) => {
                      return (<>
                        <tr style={{ textAlign: 'center', fontSize: '0.68em' }}>
                          <td style={{ verticalAlign: 'middle', fontSize: '0.85em' }}>{e?.contrato}</td>
                          <td style={{ verticalAlign: 'middle' }}>{e?.banco?.codigo} - {e?.banco?.descricao}</td>
                          <td style={{ verticalAlign: 'middle' }}>{e?.situacao}</td>
                          <td style={{ verticalAlign: 'middle' }}>Averbação nova</td>
                          <td style={{ verticalAlign: 'middle' }}>{e?.dataInclusao ? formatDateNew(e?.dataInclusao) : null}</td>
                          <td style={{ verticalAlign: 'middle' }}>{e?.valorLimiteCartao ? formatarValorEmDinheiro(e?.valorLimiteCartao) : null}</td>
                          <td style={{ verticalAlign: 'middle' }}>{e?.valorReservado ? formatarValorEmDinheiro(e?.valorReservado) : null}</td>
                        </tr>
                      </>)
                    }) : (<>
                      <tr style={{ textAlign: 'center' }} >
                        <td colspan="7">Não existem dados</td>
                      </tr>
                    </>)}
                  </tbody>
                </table>
                <div>
                  <p style={{ fontSize: '0.775em', lineHeight: '1.3em' }}>
                    *Contratos que comprometem a margem consignável.
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section >
  </>
  )
}

export default Online;