// componenentes
import Breadcrumb from "../../components_helpers/breadcrumb";
import LoadingPage from "../../components_helpers/loading";
// suporte
import {
  formatarValorEmDinheiro,
  formatCPF,
  formatName,
  formatDateYYYYMMDD,
  formatRG,
  formatarNumeroTelefone,
  formatarCEP,
  formatarCNPJ,
} from "../../helpers/FormatData";

const Fgts = (data) => {
  // var's
  const obj = data.data;
  const cadastrals = obj.CADASTRAIS;
  const contacts = obj.TELEFONES;
  const address = obj.ENDERECOS;
  const emails = obj.EMAILS;
  const death = obj.OBITO;
  const connectedPeople = obj.PESSOASLIGADAS;
  const consumeProfile = obj.PERFILCONSUMO;
  const cadastralSituation = obj.SITUACAOCADASTRAL;
  const lastEnterprises = obj.ULTIMAEMPRESALIGADA;
  const fgt = obj.FGTS;

  if (!obj) {
    return <LoadingPage />;
  }
  // func's
  const handlePrintToPDF = () => {
    const element0 = document.querySelector("header");
    const element1 = document.querySelector(".pagetitle");
    element0.style.setProperty("display", "none", "important");
    element1.style.setProperty("display", "none", "important");
    // Chamar a função de impressão
    window.print();
    element0.style.removeProperty("display");
    element1.style.removeProperty("display");
  };

  return (
    <>
      <Breadcrumb
        name={`Consulta ${formatCPF(cadastrals?.CPF)}`}
        button={[
          {
            name: "Imprimir Consulta",
            colorBtn: "primary",
            click: handlePrintToPDF,
            value: data,
            view: true,
          },
        ]}
      />
      <section className="section contact">
        <div className="row gy-4">
          <div className="col-xl-12">
            <div className="row">
              <div className="col-lg-12">
                <div className="info-box card">
                  <h3>FGTS</h3>
                  <p>
                    <b>Flag FGTS : </b>
                    {fgt?.FLAG_FGTS || "Indefinido"}
                  </p>
                  <p>
                    <b>Saque : </b>
                    {formatarValorEmDinheiro(fgt?.FLSAQUE ? fgt?.FLSAQUE : 0) ||
                      "Indefinido"}
                  </p>
                  <p>
                    <b>Valor Presumido : </b>
                    {formatarValorEmDinheiro(
                      fgt?.VLR_PRESUMIDO ? fgt?.VLR_PRESUMIDO : 0
                    ) || "Indefinido"}
                  </p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="info-box card">
                  <h3>Dados Cadastrais</h3>
                  <div className="row">
                    <div className="col-lg-6">
                      <p>
                        <b>Nome : </b>
                        {formatName(cadastrals?.NOME) || "Indefinido"}
                      </p>
                      <p>
                        <b>CPF : </b>
                        {formatCPF(cadastrals?.CPF) || "Indefinido"}
                      </p>
                      <p>
                        <b>CPF Mãe : </b>
                        {formatCPF(cadastrals?.CPF_MAE) || "Indefinido"}
                      </p>
                      <p>
                        <b>Nome Mãe : </b>
                        {formatName(cadastrals?.NOME_MAE) || "Indefinido"}
                      </p>
                      <p>
                        <b>Noem Pai : </b>
                        {formatName(cadastrals?.NOME_PAI) || "Indefinido"}
                      </p>
                      <p>
                        <b>Nascimento Mãe : </b>
                        {formatDateYYYYMMDD(cadastrals?.NASC_MAE) ||
                          "Indefinido"}
                      </p>
                      <p>
                        <b>Sexo : </b>
                        {formatName(cadastrals?.SEXO) || "Indefinido"}
                      </p>
                      <p>
                        <b>Dívida Ativa : </b>
                        {cadastrals?.DIVIDAATIVADAUNIAO_FLAG_DAU ||
                          "Indefinido"}
                      </p>
                      <p>
                        <b>Estado Cívil : </b>
                        {formatName(cadastrals?.ESTADOCIVIL) || "Indefinido"}
                      </p>
                      <p>
                        <b>Fonte de Renda : </b>
                        {cadastrals?.FONTE_DE_RENDA || "Indefinido"}
                      </p>
                      <p>
                        <b>Geração : </b>
                        {formatName(cadastrals?.GERACAO) || "Indefinido"}
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <p>
                        <b>Idade : </b>
                        {cadastrals?.IDADE || "Indefinido"}
                      </p>
                      <p>
                        <b>Nacionalidade : </b>
                        {cadastrals?.NACIONALIDADE || "Indefinido"}
                      </p>
                      <p>
                        <b>Orgão Emissor : </b>
                        {formatName(cadastrals?.ORGAOEMISSOR) || "Indefinido"}
                      </p>
                      <p>
                        <b>Persona Demografica : </b>
                        {cadastrals?.PERSONADEMOGRAFICA || "Indefinido"}
                      </p>
                      <p>
                        <b>Escolaridade : </b>
                        {formatName(cadastrals?.POSSIVELESCOLARIDADE) ||
                          "Indefinido"}
                      </p>
                      <p>
                        <b>Possível Profissão : </b>
                        {formatName(cadastrals?.POSSIVELPROFISSAO) ||
                          "Indefinido"}
                      </p>
                      <p>
                        <b>Renda : </b>
                        {formatarValorEmDinheiro(cadastrals?.RENDA)}
                      </p>
                      <p>
                        <b>RG : </b>
                        {formatRG(cadastrals?.RG) || "Indefinido"}
                      </p>
                      <p>
                        <b>Score : </b>
                        {cadastrals?.SCORE || "Indefinido"}
                      </p>

                      <p>
                        <b>Signo : </b>
                        {formatName(cadastrals?.SIGNO) || "Indefinido"}
                      </p>
                      <p>
                        <b>Tempo Empreendedor : </b>
                        {cadastrals?.TEMPO_EMPREENDEDOR || "Indefinido"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="info-box card">
                  <h3>Contatos</h3>
                  {contacts && contacts.length > 0 ? (
                    contacts.map((e, index) => {
                      return (
                        <>
                          {index > 0 && (
                            <>
                              <hr />
                            </>
                          )}
                          <p>
                            <b>Tel {index} : </b>
                            {formatarNumeroTelefone(e?.DDD + e?.TELEFONE) ||
                              "Indefinido"}
                          </p>
                          <p>
                            <b>Flag Hot : </b> {e?.FLHOT || "Indefinido"}
                          </p>
                          <p>
                            <b>WhatsApp : </b> {e?.FLWHATSAPP || "Indefinido"}
                          </p>
                          <p>
                            <b>Operadora : </b>{" "}
                            {formatName(e?.OPERADORA) || "Indefinido"}
                          </p>
                          <p>
                            <b>Procon : </b> {e?.PROCON || "Indefinido"}
                          </p>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <p>Não existem Telefones</p>
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="info-box card">
                  <h3>Endereços</h3>
                  {address && address.length > 0 ? (
                    address.map((e, index) => {
                      return (
                        <>
                          {index > 0 && (
                            <>
                              <hr />
                            </>
                          )}
                          <p>
                            <b>CEP {index} : </b>{" "}
                            {formatarCEP(e?.CEP) || "Indefinido"}
                          </p>
                          <p>
                            <b>Cidade : </b>{" "}
                            {formatName(e?.CIDADE) || "Indefinido"}
                          </p>
                          <p>
                            <b>Bairro : </b>{" "}
                            {formatName(e?.BAIRRO) || "Indefinido"}
                          </p>
                          <p>
                            <b>Complemento : </b>{" "}
                            {e?.COMPLEMENTO || "Indefinido"}
                          </p>
                          <p>
                            <b>Logradouro : </b>{" "}
                            {formatName(e?.LOGRADOURO) || "Indefinido"}
                          </p>
                          <p>
                            <b>Número : </b> {e.NUMERO || "Indefinido"}
                          </p>
                          <p>
                            <b>UF : </b> {e.UF || "Indefinido"}
                          </p>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <p>Não existem Endereços</p>
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="info-box card">
                  <h3>Pessoas Ligadas</h3>
                  {connectedPeople && connectedPeople.length > 0 ? (
                    connectedPeople.map((e, index) => {
                      return (
                        <>
                          {index > 0 && (
                            <>
                              <hr />
                            </>
                          )}
                          <p>
                            <b>Cpf {index} : </b>{" "}
                            {formatCPF(e?.CPF) || "Indefinido"}
                          </p>
                          <p>
                            <b>Nome :</b> {formatName(e?.NOME) || "Indefinido"}
                          </p>
                          <p>
                            <b>Vínculo : </b>{" "}
                            {formatName(e?.VINCULO) || "Indefinido"}
                          </p>
                          <p>
                            <b>Nascimento : </b>{" "}
                            {formatDateYYYYMMDD(e?.NASC) || "Indefinido"}
                          </p>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <p>Não existem Endereços</p>
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="info-box card">
                  <h3>Perfil de Consumo</h3>
                  {consumeProfile && (
                    <>
                      <p>
                        <b>Consultado nos útimos 6 meses :</b>{" "}
                        {consumeProfile?.CONSULTADOS_6MESES || "Indefinido"}
                      </p>
                      <p>
                        <b>Consultado nos útimos 12 meses :</b>{" "}
                        {consumeProfile?.CONSULTADOS_12MESES || "Indefinido"}
                      </p>
                      <p>
                        <b>Consumo : </b>{" "}
                        {consumeProfile?.CONSUMO || "Indefinido"}
                      </p>
                      <p>
                        <b>Persona Digital : </b>{" "}
                        {consumeProfile?.PERSONADIGITAL || "Indefinido"}
                      </p>
                      <p>
                        <b>Possível Aposentado : </b>{" "}
                        {consumeProfile?.POSSIVEL_APOSENTADO || "Indefinido"}
                      </p>
                      <p>
                        <b>Proprensão de pagamento : </b>{" "}
                        {formatarValorEmDinheiro(
                          consumeProfile?.PROPENSAO_PAGAMENTO
                        ) || "Indefinido"}
                      </p>
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-12">
                <div className="info-box card">
                  <h3>Últimas Empresas</h3>
                  {lastEnterprises && lastEnterprises.length > 0 ? (
                    lastEnterprises.map((e, index) => {
                      return (
                        <>
                          {index > 0 && (
                            <>
                              <hr />
                            </>
                          )}
                          <p>
                            <b>CNPJ : </b>{" "}
                            {formatarCNPJ(e?.CNPJ) || "Indefinido"}
                          </p>
                          <p>
                            <b>Razão Social : </b> {e?.RAZAO || "Indefinido"}
                          </p>
                          <p>
                            <b>Data Desligamento : </b>{" "}
                            {e?.DT_DESLIGAMENTO || "Indefinido"}
                          </p>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <p>Não existem Endereços</p>
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="info-box card">
                  <h3>Situação Cadastral</h3>
                  <p>
                    <b>Situação : </b>{" "}
                    {formatName(cadastralSituation?.DESCRICAO) || "Indefinido"}
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="info-box card">
                  <h3>Emails</h3>
                  {emails && emails.length > 0 ? (
                    emails.map((e, index) => {
                      return (
                        <>
                          {index > 0 && (
                            <>
                              <hr />
                            </>
                          )}
                          <p>
                            <b>Email {index} : </b> {e?.EMAIL || "Indefinido"}
                          </p>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <p>Não existem Emails</p>
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="info-box card">
                  <h3>Óbito</h3>
                  <p>
                    <b>Situação : </b> {death?.FLOBITO}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Fgts;
