import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import './bootstrap.css';

import "./bootstrap/assets/vendor/bootstrap/css/bootstrap.min.css"
import "./bootstrap/assets/vendor/bootstrap-icons/bootstrap-icons.css"
import "./bootstrap/assets/vendor/boxicons/css/boxicons.min.css"
import "./bootstrap/assets/vendor/quill/quill.snow.css"
import "./bootstrap/assets/vendor/quill/quill.bubble.css"
import "./bootstrap/assets/vendor/remixicon/remixicon.css"
import "./bootstrap/assets/vendor/simple-datatables/style.css"

import "./bootstrap/assets/css/style.css"
import "./bootstrap/assets/css/css_consulta_top.css"


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

reportWebVitals();
