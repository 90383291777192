// react
import { useEffect, useState } from "react";
// componentes
import Breadcrumb from "../components_helpers/breadcrumb";
import MessageDisplay from "../messages/messageDisplay";
import LoadingPage from "../components_helpers/loading";
// suporte
import { formatName } from "../helpers/FormatData";
import { ApiRequest } from "../helpers/ApiRequest";

const Api = () => {
  const url = `https://api.topconsulta.com.br`;
  const auth = JSON.parse(localStorage.getItem("user"));
  const api = new ApiRequest();
  const msg = new MessageDisplay();
  const [data, setData] = useState("");
  const [token, setToken] = useState(auth.token);

  const getTypeApi = async () => {
    const result = await api.get(`get-dash`);
    if (result.error === true) {
      return msg.error("Api", result.data);
    }
    setData(result.data.queries);
  };

  const newToken = async (e) => {
    const result = await api.post(`new-token`, e, true, { token });
    if (result.error === true) {
      return msg.error("Api", result.data);
    }
    setToken(result.data.token);
    auth.token = result.data.token;
    localStorage.setItem("user", JSON.stringify(auth));
  };

  useEffect(() => {
    getTypeApi();
  }, [token]);

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <>
      <Breadcrumb name={"Api(s) Disponíveis"} />
      <section className="section contact">
        <div className="row gy-4">
          <div className="col-xl-12">
            <div className="row">
              <div className="info-box card">
                <form
                  onSubmit={async (e) => {
                    await newToken(e);
                  }}
                  className="row g-3"
                >
                  <div className="col-12">
                    <label htmlFor="inputNanme4" className="form-label">
                      Token
                    </label>
                    <input
                      disabled={true}
                      value={token}
                      type="text"
                      className="form-control disable border border-primary"
                      placeholder="token"
                      name="token"
                    />
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn btn-primary">
                      Gerar Novo Token
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-xl-12">
            <div className="row">
              <div className="info-box card">
                <h3 className="text-primary">
                  Consulta com Formatador
                  <span
                    className="badge rounded-pill bg-success"
                    style={{ position: "absolute", top: "20px", right: "20px" }}
                  >
                    Novidade!
                  </span>
                </h3>
                <h6 className="mt-2">
                  {`A consulta com formatador foi implementada em qualquer tipo de consulta listada abaixo, basta que adicione o parâmetro "formatter" na url, e após o documento informe o tipo de formatador`}
                </h6>
                <div>
                  <p>
                    <b>Url: </b>
                    {url}/api/consulta/formatter/<strong>[documento]</strong>/
                    <strong>[formatador]</strong>/
                    <strong>[tipo da consulta]</strong>/{token}
                  </p>
                  <p>
                    <b>Descrição: </b>
                  </p>
                  <p>
                    <b>DOCUMENTO UTILIZADO: </b>No campo <b>[documento]</b> você
                    irá utilizar o n° de documento do cliente, de acordo com o
                    tipo da consulta listada abaixo - (CPF, BENEFÍCIO OU CNPJ)
                  </p>
                  <p>
                    <b>Tipo da Consulta: </b>No campo
                    <b>[[tipo da consulta]]</b> você irá utilizar de acordo com
                    o tipo da consulta listada abaixo
                  </p>
                </div>
                <hr className="mb-0" />
                <div className="col">
                  <div className="col-4">
                    <h5 className="mt-3 mb-0 pb-2 fw-semibold text-primary mb-2">
                      Formatadores aceitos:
                    </h5>
                    <strong>BNF:</strong> Número de benefício <br />
                    <strong>CPF:</strong> CPF <br />
                    <strong>CNPJ:</strong> CNPJ <br />
                  </div>
                  <p className="mt-1 text-danger">
                    (Nós trataremos o documento passado, iremos completar com os
                    zeros faltantes e retiraremos quaisquer máscaras aplicadas
                    ao documento)
                  </p>
                  <hr className="mb-0" />

                  <div className="col">
                    <h5 className="mt-3 mb-0 pb-2 fw-semibold text-primary">
                      Tipos de consultas aceitos:
                    </h5>
                    <div className="col mt-2">
                      <strong>saldo_in100:</strong> In100 <br />
                      <strong>saldo_extrato_online:</strong> Extrato Online
                      <br />
                      <strong>saldo_extrato_off:</strong> Extrato Offline
                      (Benefício)
                      <br />
                      <strong>saldo_siape:</strong> Siape <br />
                      <strong>saldo_fgts:</strong> FGTS <br />
                      <strong>saldo_phone:</strong> Telefone
                      <br />
                      <strong>saldo_consulta_geral:</strong> Cadastro Completo
                      (PF)
                      <br />
                      <strong>saldo_consulta_geral_cnpj:</strong> Cadastro
                      Completo (PJ) <br />
                      <strong>saldo_extrato_offline_cpf:</strong> Extrato
                      Offline (CPF) <br />
                    </div>
                    <p className="mt-1 text-danger">
                      (Adicione um tipo de consulta aceito e coerente com a
                      consulta)
                    </p>
                  </div>
                </div>
              </div>
              {data.length > 0 &&
                data.map((e) => {
                  return (
                    <>
                      {e.status && (
                        <>
                          <div className="info-box card">
                            <h3 className="pb-2 fw-semibold text-primary">
                              {formatName(e.name)}
                            </h3>
                            <div className="row">
                              <div className="col-lg-12">
                                <p>
                                  <b>Modelo de Url: </b>
                                  {url}/api/consulta/[document]/{e.type}/{token}
                                </p>
                                <p>
                                  <b>Descrição: </b>
                                </p>
                                <p>
                                  <b>Documento Utilizado: </b>No campo{" "}
                                  <b>[document]</b> você irá utilizar{" "}
                                  <b>
                                    {e.format_type === "cpf"
                                      ? "CPF"
                                      : e.format_type === "bnf"
                                      ? "BENEFÍCIO"
                                      : "CNPJ"}
                                  </b>
                                </p>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Api;
