// React
import React from "react";
// Componentes
import Breadcrumb from "../../components_helpers/breadcrumb";
import LoadingPage from "../../components_helpers/loading";
import TryAgain from "../../components_helpers/tryAgain";
// suportes
import {
  formatarValorEmDinheiro,
  formatCPF,
  formatName,
} from "../../helpers/FormatData";
// css
import "../../../assets/css/ficha.css";

const In100Plus = ({ data }) => {
  // var's
  const obj = data;
  // func's
  const handlePrintToPDF = () => {
    const element0 = document.querySelector("header");
    const element1 = document.querySelector(".pagetitle");
    element0.style.setProperty("display", "none", "important");
    element1.style.setProperty("display", "none", "important");
    // Chamar a função de impressão
    window.print();
    element0.style.removeProperty("display");
    element1.style.removeProperty("display");
  };

  const calcularIdade = (dataNascimento) => {
    if (!!dataNascimento && dataNascimento != null) {
      const partesData = dataNascimento.split('/');
      const diaNasc = parseInt(partesData[0], 10);
      const mesNasc = parseInt(partesData[1], 10) - 1;
      const anoNasc = parseInt(partesData[2], 10);
      const hoje = new Date();
      const dataNasc = new Date(anoNasc, mesNasc, diaNasc);
      let idade = hoje.getFullYear() - dataNasc.getFullYear();
      const mesAtual = hoje.getMonth();
      const diaAtual = hoje.getDate();

      if (
        mesAtual < mesNasc ||
        (mesAtual === mesNasc && diaAtual < diaNasc)
      ) {
        idade--;
      }

      return `(${idade} anos)`;
    }

    return ''
  }

  if (!obj?.nome) {
    return <TryAgain />;
  }

  if (!obj) {
    return <LoadingPage />;
  }

  return (
    <>
      <Breadcrumb
        name={`CONSULTA ${obj?.beneficio}`}
        button={[
          {
            name: "Imprimir",
            colorBtn: "primary",
            click: handlePrintToPDF,
            value: data,
            view: true,
          },
        ]}
      />
      <section className="section contact">
        <div className="row gy-4">
          <div className="col-xl-12">
            <div className="row">
              <div style={{ textAlign: "center" }}>
                <h4>Demonstrativo Simples Para Conferência.</h4>
                <h3>
                  <b>IN100 PLUS</b>
                </h3>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row py-4 px-2">
                    <h5 className="px-0">
                      <i className="fa fa-user-o" aria-hidden="true"></i>{" "}
                      <span>{formatName(obj?.nome) || "Não Informado"}</span>
                    </h5>
                    <p className="px-0 py-0 my-0">
                      <b>CPF : </b> {formatCPF(obj?.cpf) || "Indefinido"}
                    </p>
                    <p className="px-0 py-0 my-0">
                      <b>Benefício :</b> {obj?.beneficio || "Indefinido"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="info-box card">
                <h3>Benefício</h3>
                <h5>{formatName(obj.descricao)}</h5>
                <br />
                <div className="row">
                  <div className="col-lg-6">
                    <p>
                      <b>Meio Pagamento :</b>{" "}
                      {obj?.meioPagamento || "Indefinido"}
                    </p>
                    <p>
                      <b>Banco :</b> {obj?.banco || "Indefinido"}
                    </p>
                    <p>
                      <b>Agência :</b> {obj?.agencia || "Indefinido"}
                    </p>
                    <p>
                      <b>Conta :</b> {obj?.conta ? obj?.conta : "Não Informado"}
                    </p>
                    <p>
                      <b>Tipo de Conta :</b> {obj?.tipo_conta || "Indefinido"}
                    </p>
                    <p>
                      <b>UF Pagamento :</b> {obj?.uf_pagamento || "Indefinido"}
                    </p>
                    <p>
                      <b>Pensão Alimentícia :</b>{" "}
                      {obj?.pensaoAlimenticia === false ? "Não" : "Sim"}
                    </p>
                    <p>
                      <b>Possui Procurador :</b>{" "}
                      {obj?.possuiProcurador === false ? "Não" : "Sim"}
                    </p>
                    <p>
                      <b>Possui Representante Legal Procurador :</b>{" "}
                      {obj?.possuiRepresentante === false ? "Não" : "Sim"}
                    </p>
                    {obj.possuiRepresentante && (
                      <>
                        <p>
                          <b>Nome Representante :</b>{" "}
                          {formatName(obj?.nomeRepresentante) || "Indefinido"}
                        </p>
                        <p>
                          <b>CPF Representante :</b>{" "}
                          {formatCPF(obj?.cpfRepresentante) || "Indefinido"}
                        </p>
                      </>
                    )}
                  </div>
                  <div className="col-lg-6">
                    <p>
                      <b>Bloqueado :</b>{" "}
                      {obj?.blo1_emp === false ? "Não" : "Sim"}
                    </p>
                    <p>
                      <b>Situação :</b> {obj?.situacao || "Indefinido"}
                    </p>
                    <p>
                      <b>DDB :</b> {obj?.ddb ? obj?.ddb : "Indefinido"}
                    </p>
                    <p>
                      <b>Data Nascimento :</b>{" "}
                      {obj?.dataNascimento || "Indefinido"} <span style={{ marginLeft: '0.5em', fontWeight: 'bold  ' }}>{calcularIdade(obj?.dataNascimento)}</span>
                    </p>
                    <p>
                      <b>Margem :</b>{" "}
                      {formatarValorEmDinheiro(obj?.margem_35) || "Indefinido"}
                    </p>
                    <p>
                      <b>Margem RMC :</b>{" "}
                      {formatarValorEmDinheiro(obj?.margem_rmc) || "Indefinido"}
                    </p>
                    <p>
                      <b>Limite RMC :</b>{" "}
                      {formatarValorEmDinheiro(obj?.valorLimiteRMC) ||
                        "Indefinido"}
                    </p>
                    <p>
                      <b>Margem RCC :</b>{" "}
                      {formatarValorEmDinheiro(obj?.margem_rcc) || "Indefinido"}
                    </p>
                    <p>
                      <b>Limite RCC :</b>{" "}
                      {formatarValorEmDinheiro(obj?.valorLimiteRCC) ||
                        "Indefinido"}
                    </p>
                    <p>
                      <b>Quantidade Empréstimos :</b>{" "}
                      {obj?.qtde_ativos_suspensos || "Indefinido"}
                    </p>
                    <p>
                      <b>Elegível Empréstimo :</b>{" "}
                      {obj?.permite_emp === false ? "Não" : "Sim"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default In100Plus;
