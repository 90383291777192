import { useEffect, useState } from "react";
import Breadcrumb from "../components_helpers/breadcrumb";
import { Modal, Popover } from "antd";
import { Link } from "react-router-dom";
import { ApiRequest } from "../helpers/ApiRequest";
import MessageDisplay from "../messages/messageDisplay";
import Pagination from "../helpers/Paginate";
import { formatName } from "../helpers/FormatData";

const Users = () => {
  const api = new ApiRequest();
  const msg = new MessageDisplay();
  const auth = JSON.parse(localStorage.getItem("user"));

  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [get, setGet] = useState(false);
  const pageSize = 10;
  
  const getUsers = async () => {
    setGet(false);
    const result = await api.get(
      `user/listUser/${auth.id}?page=${offset}&pageSize=${pageSize}`
    );
    if (result.error === true) {
      return msg.error("Usuários", result.data);
    }
    setTotal(result.data.total);
    setData(result.data.users);
  };

  const openModal = () => {
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
  };

  const handlePage = async (e) => {
    let totalProx = (e - 1) * 10;
    setOffset(totalProx);
    setCurrentPage(e);
    setGet(true);
  };

  const searchUser = async (e) => {
    const result = await api.post(`search-users`, e);
    if (result.error === true) {
      return msg.error("Pesquisa", result.data);
    }

    if (result.data.length <= 0) {
      return msg.error(
        "Pesquisa",
        "Não foram encontrados resultados para sua pesquisa"
      );
    }

    setShow(false);
    setData(result.data);
  };

  if (get) {
    getUsers();
  }

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <Breadcrumb
        name={"Usuários"}
        button={[
          {
            name: "Pesquisar Usuário",
            colorBtn: "primary",
            click: openModal,
            value: "insert",
            view: true,
          },
        ]}
      />
      <section className="section">
        <div className="row">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Usuários</h5>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">E-mail</th>
                    <th scope="col">Level</th>
                    <th scope="col">Status</th>
                    <th style={{ textAlign: "center" }} scope="col">
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.length > 0 &&
                    data.map((e, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <th scope="row">{formatName(e.name)}</th>
                            <td>{e?.email}</td>
                            <td>{formatName(e?.level)}</td>
                            <td>{formatName(e?.status)}</td>
                            {/* <td>{e?.type_consult ? e.type_consult : null}</td> */}
                            <td style={{ textAlign: "center" }}>
                              <Popover
                                content={
                                  <div>
                                    {e.balance &&
                                      e.balance.map((value) => {
                                        return (
                                          <>
                                            {`${value.name}: ${value.saldo}`}
                                            <br></br>
                                          </>
                                        );
                                      })}
                                  </div>
                                }
                                title={`Recargas: ${e.name}`}
                              >
                                <button
                                  className="btn btn-sm btn-success"
                                  title="Saldos"
                                >
                                  <i className="bi bi-currency-dollar text-white"></i>{" "}
                                </button>
                              </Popover>
                              <Link to={`/painel/editar/usuario/${e.id}`}>
                                {" "}
                                <button
                                  className="btn btn-sm btn-info"
                                  title="Editar Usuário"
                                >
                                  <i className="bi bi-pen-fill text-white"></i>
                                </button>
                              </Link>
                              <Link to={`/painel/editar/recargas/${e.id}`}>
                                {" "}
                                <button
                                  className="btn btn-sm btn-warning"
                                  title="Histório de Recargas"
                                >
                                  <i className="bi bi-cart-fill text-white"></i>
                                </button>
                              </Link>
                              {e.level === "revenda" && (
                                <>
                                  <Link
                                    to={`/painel/usuarios/relacionados/${e.id}`}
                                  >
                                    {" "}
                                    <button
                                      className="btn btn-sm btn-primary"
                                      title="Usuários Relacionados"
                                    >
                                      <i className="bi bi-person-fill"></i>
                                    </button>
                                  </Link>
                                </>
                              )}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
              <Pagination
                total={total}
                currentPage={currentPage}
                onPageChange={(e) => handlePage(e)}
              />
            </div>
          </div>
        </div>
      </section>

      <Modal
        title={"Pesquisar Usuário"}
        open={show}
        onCancel={closeModal}
        footer={null}
      >
        <form onSubmit={(e) => searchUser(e)} className="row g-3">
          <div className="col-12">
            <label htmlFor="inputNanme4" className="form-label">
              Crédito:
            </label>
            <input
              type="text"
              className="form-control border border-primary"
              placeholder="Digite Nome / E-mail"
              name="search"
            />
          </div>
          <div className="text-center">
            <button type="submit" className="btn btn-sm btn-success">
              Pesquisar Usuário
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default Users;
