import React, { useState, useEffect } from "react";
import Footer from "./footer";
import Header from "./header";
import Aside from "./aside";
import { Helmet } from "react-helmet";
import { ApiRequest } from "../helpers/ApiRequest";
import MessageDisplay from "../messages/messageDisplay";
import WhatsButton from "../whatsapp/button";

const Menu = ({ path, isLoggeIn, children }) => {
  const [white, setWhite] = useState("");
  const [view, setView] = useState(false);
  const msg = new MessageDisplay();
  const api = new ApiRequest();
  const currentDomain = window.location.hostname;

  if (!path.includes("painel") || isLoggeIn !== "true") {
    return <>{children}</>;
  }

  const whiteLabel = async () => {
    setView(false);
    const result = await api.get(`get-whitelabel/${currentDomain}`);
    console.log(result)
    if (result.error && result.data === 404) {
      setError(true);
      return msg.error(
        "Error",
        "Desculpe mais não foi possível recuperar o white label"
      );
    } else {
      setView(true);
      setWhite(result.data);
    }
  };

  useEffect(() => {
    whiteLabel();
  }, []);

  if (!view) {
    return (
      <>
        <p>Aguarde Carregado ...</p>
      </>
    );
  }


  return (
    <>
      <Helmet>
        <title>
          {white?.name ? white?.name : "CONSULTA TOP"} - Painel de Login
        </title>
      </Helmet>
      <section>
        <Header logo={white.logo} />
        <Aside />
        <main id="main" className="main">
          {children}
        </main>
        <WhatsButton />
        <Footer />
      </section>
    </>
  );
};

export default Menu;
