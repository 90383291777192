import { useState } from "react";
import { ApiRequest } from "../helpers/ApiRequest";
import MessageDisplay from "../messages/messageDisplay";

const WhatsButton = () => {
  const auth = JSON.parse(localStorage.getItem("user"));
  const id = auth.id_ref;
  const api = new ApiRequest();
  const msg = new MessageDisplay();
  const [isHovered, setIsHovered] = useState(false);

  const getWhats = async () => {
    const result = await api.get(`get-whats/true/${id}`);
    if (result.error === true) {
      return msg.error(
        "Whats",
        "Desculpe mais não foi possível localizar o whatsapp do revendedor!"
      );
    }
    if (result.data?.whatsapp) {
      window.open(
        `https://api.whatsapp.com/send?phone=55${result.data.whatsapp}&text=Ol%C3%A1%2C%20gostaria%20de%20adquirir%20mais%20saldo%20no%20consulta%20top%2C%20por%20gentileza.`,
        "_blank"
      );
    } else {
      return msg.error(
        "Whats",
        "Desculpe mais não foi possível localizar o whatsapp do revendedor!"
      );
    }
  };

  const buttonStyle = {
    zIndex: 1000,
    transition: "transform 300ms",
    cursor: "pointer",
  };

  if (isHovered) {
    buttonStyle.transform = "scale(1.05)";
  }

  const tooltipStyle = {
    width: "120px",
    display: isHovered ? "block" : "none",
    position: "absolute",
    bottom: "100%",
    left: "0%",
    transform: "translateX(-50%)",
    padding: "8px",
    background: "#333",
    color: "#fff",
    borderRadius: "4px",
    zIndex: "1",
  };

  return (
    <>
      <div
        className="m-4 position-fixed end-0 bottom-0 d-flex items-align-center justify-content-center"
        style={buttonStyle}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => getWhats()}
      >
        <div
          className="bg-success rounded-circle d-flex align-items-center justify-content-center shadow-lg"
          style={{ width: "65px", height: "65px" }}
        >
          <i className="bi bi-whatsapp fs-2 text-white position-absolute"></i>
          <div className="mb-2" style={tooltipStyle}>
            Solicite saldo!
          </div>
        </div>
      </div>
    </>
  );
};
export default WhatsButton;
