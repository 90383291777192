import React from 'react';

const Pagination = ({ total, currentPage, onPageChange }) => {
    const totalPages = Math.ceil(total / 10); // Supondo 10 itens por página
    const pageNumbers = [];

    // Lógica para calcular quais números de página exibir
    if (totalPages <= 5) {
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }
    } else if (currentPage <= 3) {
        for (let i = 1; i <= 5; i++) {
            pageNumbers.push(i);
        }
    } else if (currentPage >= totalPages - 2) {
        for (let i = totalPages - 4; i <= totalPages; i++) {
            pageNumbers.push(i);
        }
    } else {
        for (let i = currentPage - 2; i <= currentPage + 2; i++) {
            pageNumbers.push(i);
        }
    }

    return (
        <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-end">
                {currentPage > 1 && (
                    <li className="page-item">
                        <a className="page-link" href="#" onClick={() => onPageChange(currentPage - 1)}>
                            Previous
                        </a>
                    </li>
                )}
                {pageNumbers.map((pageNumber) => (
                    <li
                        key={pageNumber}
                        className={`page-item ${pageNumber === currentPage ? 'active' : ''}`}
                    >
                        <a className="page-link" href="#" onClick={() => onPageChange(pageNumber)}>
                            {pageNumber}
                        </a>
                    </li>
                ))}
                {currentPage < totalPages && (
                    <li className="page-item">
                        <a className="page-link" href="#" onClick={() => onPageChange(currentPage + 1)}>
                            Next
                        </a>
                    </li>
                )}
            </ul>
        </nav>
    );
};

export default Pagination;
