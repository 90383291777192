import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import DashBoard from "../components/dash/dash";
import Login from "../components/login";
import ServerErrorPage from "../components/auth/server_error_page";
import ForbiddenPage from "../components/auth/forbidden_page";
import Consultas from "../components/consult/consultas";
import ConsultasApi from "../components/consult/consultas_api";
import logOut from "../components/auth/logout";
import Consulta from "../components/consult/consulta";
import Consultar from "../components/consult/consultar";
import UsersDefault from "../components/users/users";
import CreateUserDefault from "../components/users/create";
import EditUserDefault from "../components/users/edit_users";
import HistoryCreditDefault from "../components/users/history_credit";
import ApiDefault from "../components/api/api";
import Menu from "../components/menu/menu";
import Arquivos from "../components/arquivos/arquivos";
import NewFile from "../components/arquivos/novo_arquivo";
import Perfil from "../components/profile/perfi";
import WhatsApp from "../components/whatsapp/whatsapp";
import UsersRelated from "../components/users/user_related";

const PainelRouters = ({ isLoggeIn }) => {
  const [user, setData] = useState(null);
  const [errors, setErrors] = useState(false);

  const getInfoCLient = async () => {
    const tokenLocalStorage = window.localStorage.getItem("token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_HTTP}/user/userData/${tokenLocalStorage}/`,
        {
          method: "GET",
          crossDomain: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            authorization: `Bearer ${tokenLocalStorage}`,
          },
        }
      );

      if (isLoggeIn === true && response.status === 403) {
        window.location.href = "../login";
        return logOut;
      }
      const dataUser = await response.json();
      setData(dataUser);
    } catch {
      setData(false);
      setErrors(true);
    }
  };

  useEffect(() => {
    getInfoCLient();
  }, []);

  if (errors) {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<ServerErrorPage />} />
          <Route path="*" element={<ServerErrorPage />} />
        </Routes>
      </Router>
    );
  }

  if (user?.data.length <= 0) {
    return logOut();
  }

  const path = window.location.pathname;

  return (
    <>
      <Router>
        <Menu path={path} isLoggeIn={isLoggeIn}>
          <Routes>
            <Route
              path="/"
              element={
                isLoggeIn !== "true" ? (
                  <Login />
                ) : (
                  <Navigate to="/painel/dash" />
                )
              }
            />
            <Route
              path="/login"
              element={
                isLoggeIn !== "true" ? (
                  <Login />
                ) : (
                  <Navigate to="/painel/dash" />
                )
              }
            />
            <Route
              path="/forbidden"
              element={
                isLoggeIn !== "true" ? (
                  <Navigate to="/login" />
                ) : (
                  <ForbiddenPage />
                )
              }
              status={403}
            />
            <Route
              path="/server-error"
              element={
                isLoggeIn !== "true" ? (
                  <Navigate to="/login" />
                ) : (
                  <ServerErrorPage />
                )
              }
              status={500}
            />
            <Route
              path="/painel/dash"
              element={
                isLoggeIn !== "true" ? <Navigate to="/login" /> : <DashBoard />
              }
            />
            <Route
              path="/painel/perfil"
              element={
                isLoggeIn !== "true" ? <Navigate to="/login" /> : <Perfil />
              }
            />
            <Route
              path="/painel/consultar"
              element={
                isLoggeIn !== "true" ? <Navigate to="/login" /> : <Consultar />
              }
            />
            <Route
              path="/painel/consultas_api"
              element={
                isLoggeIn !== "true" ? <Navigate to="/login" /> : <ConsultasApi />
              }
            />
            <Route
              path="/painel/consultas"
              element={
                isLoggeIn !== "true" ? <Navigate to="/login" /> : <Consultas />
              }
            />
            <Route
              path="/painel/consulta/:id/:type"
              element={
                isLoggeIn !== "true" ? <Navigate to="/login" /> : <Consulta />
              }
            />
            <Route
              path="/painel/enriquecimento/"
              element={
                isLoggeIn !== "true" ? <Navigate to="/login" /> : <Arquivos />
              }
            />
            <Route
              path="/painel/enriquecimento/novo"
              element={
                isLoggeIn !== "true" ? <Navigate to="/login" /> : <NewFile />
              }
            />
            <Route
              path="/painel/usuarios"
              element={
                isLoggeIn !== "true" ? (
                  <Navigate to="/login" />
                ) : (
                  <UsersDefault />
                )
              }
            />
            <Route
              path="/painel/usuarios/relacionados/:id"
              element={
                isLoggeIn !== "true" ? (
                  <Navigate to="/login" />
                ) : (
                  <UsersRelated />
                )
              }
            />
            <Route
              path="/painel/cadastrar/usuario"
              element={
                isLoggeIn !== "true" ? (
                  <Navigate to="/login" />
                ) : (
                  <CreateUserDefault />
                )
              }
            />
            <Route
              path="/painel/editar/usuario/:id"
              element={
                isLoggeIn !== "true" ? (
                  <Navigate to="/login" />
                ) : (
                  <EditUserDefault />
                )
              }
            />
            <Route
              path="/painel/editar/recargas/:id"
              element={
                isLoggeIn !== "true" ? (
                  <Navigate to="/login" />
                ) : (
                  <HistoryCreditDefault />
                )
              }
            />
            <Route
              path="/painel/api"
              element={
                isLoggeIn !== "true" ? <Navigate to="/login" /> : <ApiDefault />
              }
            />
            <Route
              path="/painel/whatsapp"
              element={
                isLoggeIn !== "true" ? <Navigate to="/login" /> : <WhatsApp />
              }
            />
          </Routes>
        </Menu>
      </Router>
    </>
  );
};
export default PainelRouters;
