// biblioteca
import { Result } from "antd";

const ServerErrorPage = () => {
  return (
    <Result status="500" title="500" subTitle="Desculpe, algo deu errado." />
  );
};

export default ServerErrorPage;
