const LoadingPage = () => {
  return (
    <div
      style={{ minHeight: "100vh" }}
      className="text-center d-flex flex-column justify-content-center align-items-center"
    >
      <p className="fw-bold fs-3">Aguarde Carregando...</p>
      <figure>
        <img
          width="200px"
          src="https://www.pdfen.com/images/stories/plaatjes/pdfen/gears_360.gif"
          alt="Loading Gif"
        />
      </figure>
    </div>
  );
};
export default LoadingPage;
