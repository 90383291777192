import { Component } from "react";
import Swal from "sweetalert2";

export default class sweetAlert extends Component {
  constructor(props) {
    super(props);
    this.messageApi = Swal;
  }

  success = (text, timer = null, timerProgressBar = null) => {
    return this.messageApi.fire({
      title: "Sucesso",
      html: text,
      icon: "success",
      confirmButtonText: "Ok",
      timer: timer,
      timerProgressBar: timerProgressBar,
      appendToBody: true,
      heightAuto: false,
    });
  };

  error = (text, timer = null, timerProgressBar = null) => {
    return this.messageApi.fire({
      title: "Erro",
      html: text,
      icon: "error",
      confirmButtonText: "Ok",
      timer: timer,
      timerProgressBar: timerProgressBar,
      appendToBody: true,
      heightAuto: false,
    });
  };

  warning = (text, timer = null, timerProgressBar = null) => {
    return this.messageApi.fire({
      title: "Alerta",
      html: text,
      icon: "warning",
      confirmButtonText: "Ok",
      timer: timer,
      timerProgressBar: timerProgressBar,
      appendToBody: true,
      heightAuto: false,
    });
  };

  info = (text, timer = null, timerProgressBar = null, confirmButtonText = null, cancelButtonText = null) => {
    return this.messageApi
      .fire({
        title: "Informação",
        html: text,
        icon: "info",
        confirmButtonText: confirmButtonText != null ? confirmButtonText : 'Confirmar',
        cancelButtonText: cancelButtonText != null ? cancelButtonText : 'Cancelar',
        timer: timer,
        timerProgressBar: timerProgressBar,
        appendToBody: true,
        heightAuto: false,
      })
      .then((result) => {
        if (result.isConfirmed) {
          return true;
        }
      });
  };
}
