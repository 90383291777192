import { useEffect, useState } from "react";
import { ApiRequest } from "../helpers/ApiRequest";
import MessageDisplay from "../messages/messageDisplay";
import Breadcrumb from "../components_helpers/breadcrumb";
import moment from 'moment'

import {
  // formatDate,
  formatTypeConsult,
  formatName,
  formatCPF,
  formatarCNPJ,
} from "../helpers/FormatData";
import { Link } from "react-router-dom";
import Pagination from "../helpers/Paginate";

const Consultas = () => {
  const pageSize = 10
  const [currentPage, setCurrentPage] = useState(1);
  // const [pageSize, setPageSize] = useState(10);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState(20);
  const [get, setGet] = useState(false);

  // const auth = JSON.parse(localStorage.getItem("user"));
  const api = new ApiRequest();
  const msg = new MessageDisplay();

  const getConsults = async (loading = true) => {
    setGet(false);
    const result = await api.get(`consult/get-consults?type_search=api&page=${offset}&pageSize=${pageSize}`, loading);
    if (result.error === true) {
      return msg.error(
        "Consultas",
        "Desculpe mais não foi possível extrair suas consultas"
      );
    }
    if (!result.error) {
      setTotal(result.data.total);
      setData(result.data.consultsResult);
    }
  };

  const handlePage = async (e) => {
    let totalProx = (e - 1) * pageSize;
    setOffset(totalProx);
    setCurrentPage(e);
    setGet(true);
  };

  if (get) {
    getConsults();
  }

  useEffect(() => {

    getConsults();
    const intervalId = setInterval(() => {
      getConsults(false);
    }, 20000); // Ajuste o intervalo de tempo conforme necessário

    return () => clearInterval(intervalId); // Limpa o intervalo quando o componente for desmontado
  }, [offset, pageSize]);

  return (
    <>
      <Breadcrumb name={"Histórico de Consultas de API"} />
      <section className="section">
        {/* <div className="col-md-12">
          <div style={{ background: '#FFCFC9', padding: '10px', marginBottom: '10px', }}>
            <p className="warning">
              Prezados, as consultas Online estão apresentando instabilidade em todo o Brasil. Ao solicitar uma consulta, por favor, aguarde até que ela seja concluída como <b>FINALIZADA</b> ou retornada com um <b>ERRO</b>. O sistema tentará realizar a consulta até 15 vezes antes de retornar uma mensagem para você.</p>
          </div>
        </div> */}
        <div className="row">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Consultas</h5>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Documento</th>
                    <th scope="col">Data</th>
                    <th scope="col">Forma</th>
                    <th scope="col">status</th>
                    <th scope="col">Resposta</th>
                    <th scope="col">Tipo de Consulta</th>
                    <th style={{ textAlign: "center" }} scope="col">
                      Visualizar Consulta
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.length > 0 &&
                    data.map((e, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <th scope="row">
                              {e.document.length === 11
                                ? formatCPF(e.document)
                                : formatarCNPJ(e.document)}
                            </th>
                            <td>{e?.date ? moment(e.date).format('DD/MM/YYYY') : null}</td>
                            <td>{formatName(e?.forma_search)}</td>
                            <td>{e?.status}</td>
                            <td>{e?.status === 'finalizado' ? 'Sucesso' : e?.resposta}</td>
                            {/* <td>{e?.type_consult ? e.type_consult : null}</td> */}
                            <td>
                              {e?.type_consult
                                ? formatName(formatTypeConsult(e.type_consult))
                                : null}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {e.status === 'finalizado' && (<>
                                <Link
                                  to={`/painel/consulta/${e.document}/${e.type_consult}`}
                                >
                                  {" "}
                                  <button className="btn btn-sm btn-primary">
                                    <i className="ri-airplay-line text-white"></i>
                                  </button>
                                </Link>
                              </>)}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
              <Pagination
                total={total}
                currentPage={currentPage}
                onPageChange={(e) => handlePage(e)}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Consultas;
