import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Loading from "./components/messages/load";
import PainelRouters from "./routers/panel_routers";

function App() {
  const isLoggeIn = window.localStorage.getItem("loggeIn");

  return (
    <>
      <Loading />
      <PainelRouters isLoggeIn={isLoggeIn} />
    </>
  );
}

export default App;
