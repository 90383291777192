// react
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// componentes
import sweetAlert from "../messages/sweet";
import MessageDisplay from "../messages/messageDisplay";
// bibliotecas
// import * as XLSX from "xlsx";
import Papa from 'papaparse';
// suportes
// import { formatName } from "../helpers/FormatData";
import Breadcrumb from "../components_helpers/breadcrumb";
import { ApiRequest } from "../helpers/ApiRequest";

const NewFile = () => {
  const api = new ApiRequest();
  const msg = new MessageDisplay();
  const sweet = new sweetAlert();
  const navigate = useNavigate();
  const [data, setData] = useState("");
  const [balances, setBalances] = useState("");
  const [jsonDAta, setJsonData] = useState("");
  const [jsonList, setJsonList] = useState("");
  const [message, setMessage] = useState(false);

  const getTypeConsults = async () => {
    const result = await api.get(`get-dash`);
    if (result.error === true) {
      return msg.error("Consultas", result.data);
    }
    setData(result.data.queries);
    setBalances(result.data.saldos[0]);
    setJsonList(false);
    setJsonData(false);
  };

  const handleFileChange = async (e) => {
    const dataType = e.target.id;
    setMessage(false);

    const file = e.target?.files[0];
    setJsonData(null);
    setJsonList(null);
    if (file && !file.name.endsWith(".csv")) {
      this.setState({ jsonData: null });
      e.target.value = null;
      const msg = new MessageDisplay();
      msg.error("Files", "O arquivo precisa ser do tipo CSV.");
      return;
    }
    const reader = new FileReader();

    reader.onload = (event) => {
      const csvData = event.target.result;
      Papa.parse(csvData, {
        complete: function (results) {
          let result = results.data;

          // Filtrar linhas vazias e não definidas e verificar se os elementos são números
          result = result.filter((ar) => {
            return ar.length !== 0 && ar !== undefined && ar.every(item => /^[0-9.-]+$/.test(item));
          });

          if (!result.length) {
            const msg = new MessageDisplay();
            e.target.value = null;
            msg.error(
              "Files",
              "Forma incorreta de passar parâmetros através do CSV"
            );

            setJsonData(null);
            return;
          }

          let g = 0;
          let list = new Array();
          for (const r of result) {
            g++;
            if (g <= 5) {
              list.push(r);
            }
          }

          const balance = balances[dataType];
          if (balance === undefined || balance === 0) {
            functionTest();
            return sweet.info(
              "Desculpe mais você não possui <b>saldo</b> para executar essa ação recarregue e tente novamente",
              5000,
              true,
              "Confirmar"
            );
          } else {
            if (balance < result.length) {
              const resultDAta = result.slice(0, balance);
              setJsonData(list);
              setJsonList(resultDAta);
              setMessage(true);
            } else {
              setJsonData(list);
              setJsonList(result);
            }
          }
        },
        header: false,
        skipEmptyLines: true
      });
    };

    reader.readAsText(file);

  };

  const functionTest = async () => {
    setJsonData(null);
    setJsonList(null);

    const obj = document.querySelectorAll(".inputsFiles");
    for (const o of obj) {
      o.value = "";
    }
  };

  const sendFile = async (e) => {
    sweet
      .info(
        "Você tem certeza que deseja enviar esse <b>arquivo</b>? Lembrando que seu saldo sera <b>TEMPORARIAMENTE DEBITADO</b> e <b>DEVOLVIDO</b> ao final da conclusão do arquivo",
        null,
        null
      )
      .then(async (obj) => {
        if (obj === true) {
          const postData = {
            data: jsonList,
            type_consult: e,
            total: jsonList.length,
          };
          const result = await api.post(
            `enriquecimento-dados`,
            postData,
            false
          );

          if (result.error === true) {
            return msg.error("Erro", result.data);
          }

          navigate(`../painel/enriquecimento`);

          return msg.success(
            "Sucesso",
            "Seu arquivo está sendo higiênizado e deve ficar pronto em breve"
          );
        }
      });
  };

  useEffect(() => {
    getTypeConsults();
  }, []);
  return (
    <>
      <Breadcrumb name={"Novo Arquivo"} />
      <section className="section">
        <div className="row">
          <div className="col-12">
            <div className="row">
              {data &&
                data.map((e, index) => {
                  return (
                    <>
                      {(e.arquive && e.status === 1) && (
                        <>
                          <div className="col-xxl-4 col-md-6" key={index}>
                            <div className="card info-card sales-card">
                              <div className="card-body">
                                <div className="d-flex d-flex justify-content-between align-items-center">
                                  <h5
                                    style={{
                                      fontSize: "0.8em",
                                      paddingBottom: 0,
                                    }}
                                    className="card-title fw-bold"
                                  >
                                    {(e.name_higienizacao)}
                                    <span></span>
                                  </h5>
                                </div>
                                <p style={{ fontSize: "0.8em" }}>
                                  Saldo: {balances[e.type]}
                                </p>
                                <div className="d-flex align-items-center">
                                  <div className="ps-3">
                                    <div className="d-flex d-flex justify-content-between align-items-center">
                                      <input
                                        type="file"
                                        id={e.type}
                                        data-type={e.type}
                                        className="form-control inputsFiles border border-info"
                                        accept=".csv"
                                        onClick={functionTest}
                                        onChange={handleFileChange}
                                      />
                                      <div>
                                        <button
                                          onClick={() => sendFile(e.type)}
                                          style={{ marginLeft: "10px" }}
                                          className="btn btn-sm btn-info text-white"
                                        >
                                          Enviar
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
      {jsonDAta && (
        <>
          <section className="section">
            <div className="row">
              {message && (
                <>
                  <div
                    className="alert alert-danger alert-dismissible fade show"
                    role="alert"
                  >
                    Peço desculpas, mas seu saldo só permitirá o envio de um
                    total de <b>{jsonList.length}</b> registros. Você pode
                    recarregar seu saldo ou optar por enviar mesmo assim.
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                    ></button>
                  </div>
                </>
              )}
              <div className="card">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <td scope="col">TOTAL</td>
                      <td scope="col">{jsonList.length}</td>
                    </tr>
                    <tr>
                      <td scope="col">CPFS</td>
                      <td scope="col"></td>
                    </tr>
                  </thead>
                  <tbody>
                    {jsonDAta &&
                      jsonDAta.map((e, index) => {
                        return (
                          <>
                            <tr key={index}>
                              <td>{e}</td>
                              <td></td>
                            </tr>
                          </>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default NewFile;
