import Breadcrumb from "../components_helpers/breadcrumb";
import { ApiRequest } from "../helpers/ApiRequest";
import MessageDisplay from "../messages/messageDisplay";
import { checkPassword, checkEmail, nameAndSurname } from "../helpers/regEx";

const CreateUser = () => {
  const auth = JSON.parse(localStorage.getItem("user"));
  const api = new ApiRequest();
  const msg = new MessageDisplay();

  const createUser = async (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const email = e.target.email.value;
    const password = e.target.password.value;
    const password_confirm = e.target.password_confirm.value;
    const level = e.target.name.level;

    if (name === "" || name.length < 1)
      return msg.error("Usuário", "O nome está vazio!");

    if (!nameAndSurname(name))
      return msg.error(
        "Usuário",
        "O nome está invalido, deve conter nome e sobrenome e as letras inicias devem ser maiuculas"
      );

    if (email === "" || email.length < 1)
      return msg.error("Usuário", "O e-mail está vazio!");

    if (!checkEmail(email))
      return msg.error("Usuário", "O e-mail está invalido!");
 
    if (password === "" || password.length < 1)
      return msg.error("Usuário", "A senha está vazia!");

    if (!checkPassword(password))
      return msg.error("Usuário", "A senha está invalida!");

    if (password !== password_confirm)
      return msg.error("Usuário", "As senhas estão diferentes!");

    if (level === "") return msg.error("Usuário", "Selecione um level!");

    const result = await api.post(`register`, e);
    if (result.error === true) {
      return msg.error("Usuário", result.data);
    }
    return msg.success("Usuário", "Cadastro realizado com sucesso");
  };

  return (
    <>
      <Breadcrumb name={"Novo Usuário"} />
      <section className="section">
        <div className="row">
          <div className="col-12">
            <div className="card border">
              <div className="card-body">
                <h5 className="card-title">Cadastrar Novo Usuário</h5>
                <form onSubmit={(e) => createUser(e)} className="row g-3">
                  <div className="col-12">
                    <input
                      type="hidden"
                      className="form-control  border border-info"
                      value={auth.id}
                      placeholder="Nome"
                      name="id_ref"
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="inputNanme4" className="form-label">
                      Digite seu Nome:
                    </label>
                    <input
                      type="text"
                      className="form-control  border border-info"
                      placeholder="Carl Johnson"
                      name="name"
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="inputNanme4" className="form-label">
                      Digite seu E-mail:
                    </label>
                    <input
                      type="text"
                      className="form-control  border border-info"
                      placeholder="example@email.com"
                      name="email"
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="inputNanme4" className="form-label">
                      Digite sua Senha:
                    </label>
                    <input
                      type="password"
                      className="form-control  border border-info"
                      placeholder="*******"
                      name="password"
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="inputNanme4" className="form-label">
                      Digite a Senha novamente:
                    </label>
                    <input
                      type="password"
                      className="form-control  border border-info"
                      placeholder="*******"
                      name="password_confirm"
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="inputNanme4" className="form-label">
                      Selecione seu Level:
                    </label>
                    <select
                      className="form-control  border border-info"
                      name="level"
                    >
                      <option selected disabled value="">
                        Selecione o Level
                      </option>
                      {
                        auth.level === "master" && (
                          <>
                            <option value="revenda">Revenda</option>
                          </>
                        )
                      }
                      {
                        auth.level === "revenda" && (
                          <>
                            <option value="usuario">Usuário</option>
                          </>
                        )
                      }
                    </select>
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn btn-success">
                      Cadastrar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateUser;
