
import logOut from "../auth/logout";
import noAvatar from "../../assets/images/no_avatar.jpg";
import { Link } from "react-router-dom";
import { ApiRequest } from "../helpers/ApiRequest";
import MessageDisplay from "../messages/messageDisplay";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Header = ({logo}) => {
  const auth = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  // icons
  const [icon, setIcon] = useState(false);
  const [notificationIcon, setNotificationIcon] = useState(false);
  const [walletIcon, setWalletIcon] = useState(false);

  if (!auth) {
    logOut();
  }

  const api = new ApiRequest();
  const msg = new MessageDisplay();

  const [saldoConsult, setSaldoConsult] = useState("");
  const [clientQueries, setClientQueries] = useState("");
  const [totalNotification, setTotalNotification] = useState("");
  const [listNotifications, setListNotifications] = useState("");

  const menuOpen = () => {
    const body = document.querySelector("body");
    if (body) {
      if (!body.classList.contains("toggle-sidebar")) {
        setIcon(true);
        body.classList.add("toggle-sidebar"); // Adiciona a classe "toggle-sidebar" se não existir
      } else {
        setIcon(false);
        body.classList.remove("toggle-sidebar"); // Remove a classe "show" se já existir
      }
    }
  };

  const Profile = () => {
    const view = document.querySelector(".profile");
    if (view) {
      if (!view.classList.contains("show")) {
        view.classList.add("show"); // Adiciona a classe "show" se não existir
      } else {
        view.classList.remove("show"); // Remove a classe "show" se já existir
      }
    }
  };

  const saldosList = async () => {
    const view = document.querySelector(".saldos");
    if (view) {
      if (!view.classList.contains("show")) {
        const result = await api.get(`get-dash`);
        if (result.error && result.type === "token") {
          msg.error("Dashboard", result.data);
          setTimeout(logOut, 1000);
        }

        setSaldoConsult(result.data.saldos[0]);
        setClientQueries(result.data.queries);
        setWalletIcon(true);
        view.classList.add("show"); // Adiciona a classe "show" se não existir
      } else {
        setWalletIcon(false);
        view.classList.remove("show"); // Remove a classe "show" se já existir
      }
    }
  };

  const functionTotalNotification = async () => {
    const result = await api.get(`get-dash`);
    if (result.error && result.type === "token") {
      msg.error("Dashboard", result.data);
      setTimeout(logOut, 1000);
    }

    setSaldoConsult(result.data.saldos[0]);
    setClientQueries(result.data.queries);

    let totalNoti = 0;
    for (const e of result.data.queries) {
      if (result.data.saldos[0][e.type] <= 10) {
        totalNoti = totalNoti + 1;
      }
    }
    setTotalNotification(totalNoti);
  };

  const notificationsList = async () => {
    const view = document.querySelector(".lista");
    if (view) {
      const listNotify = [];
      if (!view.classList.contains("show")) {
        for (const e of clientQueries) {
          if (saldoConsult[e.type] <= 10) {
            listNotify.push({
              type: e.name,
              saldo: saldoConsult[e.type],
            });
          }
        }
        setListNotifications(listNotify);
        setNotificationIcon(true);

        view.classList.add("show");
      } else {
        setNotificationIcon(false);
        view.classList.remove("show"); // Remove a classe "show" se já existir
      }
      // Adiciona a classe "show" se não existir
    }
  };

  useEffect(() => {
    functionTotalNotification();
    notificationsList();
  }, []);

  return (
    <>
      <header
        id="header"
        className="header fixed-top d-flex align-items-center"
        style={{ backgroundColor: "#e3edff" }}
      >
        <div className="d-flex align-items-center justify-content-start">
          <i
            onClick={() => menuOpen()}
            className={`bi ${icon ? "bi-list" : "bi-x-lg"} toggle-sidebar-btn `}
          ></i>
          <a
            className="p-2"
            onClick={() => {
              navigate("/painel/dash");
            }}
          >
            <img
              style={{
                maxWidth: "250px",
                minWidth: "100px",
                width: "70%",
                cursor: "pointer",
              }}
              src={logo}
              alt=""
            />
          </a>
        </div>
        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">
            <li className="nav-item dropdown">
              <a
                onClick={() => notificationsList()}
                className="nav-link nav-icon show"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="true"
              >
                <i
                  className={`bi ${
                    notificationIcon ? "bi-bell-fill" : "bi-bell"
                  }`}
                ></i>
                {totalNotification > 0 && (
                  <>
                    <span className="badge bg-danger badge-number">
                      {totalNotification}
                    </span>
                  </>
                )}
              </a>
              <ul
                className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications lista show"
                data-popper-placement="bottom-end"
                style={{
                  position: "absolute",
                  inset: "0px 0px auto auto",
                  width: "450px",
                  margin: "35px",
                  transform: "translate(-16x, 38px)",
                }}
              >
                <li className="dropdown-header">
                  Você possui
                  <span className="badge rounded-pill bg-primary p-2 ms-2">
                    {totalNotification}
                  </span>{" "}
                  Notificações
                </li>
                {listNotifications &&
                  listNotifications.length > 0 &&
                  listNotifications.map((e) => {
                    return (
                      <>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="notification-item">
                          <i className="bi bi-exclamation-circle text-warning"></i>
                          <div>
                            <h4>Alerta de Saldo</h4>
                            <p>
                              Seu saldo no <b>{e.type}</b> está muito baixo, com
                              apenas <b>{e.saldo}</b> disponíveis. Recomendamos
                              que faça uma recarga agora mesmo para garantir que
                              você não fique sem acesso às consultas.
                            </p>
                          </div>
                        </li>
                      </>
                    );
                  })}
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                onClick={() => {
                  saldosList();
                }}
                className="nav-link nav-icon"
                title={"Saldos"}
                href="#"
                data-bs-toggle="dropdown"
              >
                <i
                  className={`bi  ${
                    walletIcon ? "bi-wallet-fill" : "bi-wallet"
                  }`}
                ></i>
              </a>
              <ul
                className="dropdown-menu dropdown-menu-end dropdown-menu-arrow messages saldos"
                style={{
                  position: "absolute",
                  inset: "0px 0px auto auto",
                  margin: "0px",
                  transform: "translate(-16px, 38px)",
                }}
              >
                <li className="dropdown-header">Lista dos Saldos</li>
                {clientQueries &&
                  clientQueries.map((e) => {
                    return (
                      <>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="message-item">
                          <h4 style={{ fontSize: "0.6775em" }}>
                            <span>{e.name}</span> -{" "}
                            {saldoConsult[e.type]
                              ? saldoConsult[e.type].toLocaleString("pt-BR")
                              : 0}
                          </h4>
                        </li>
                      </>
                    );
                  })}
              </ul>
            </li>
            <li className="nav-item dropdown pe-3">
              <a
                onClick={(e) => {
                  Profile(e);
                }}
                className="nav-link nav-profile d-flex align-items-center pe-0"
                href="#"
                data-bs-toggle="dropdown"
              >
                <p
                  className="d-none d-md-block pe-3 fw-bold"
                >
                  {auth?.name}
                </p>
                <img src={noAvatar} alt="Profile" className="rounded-circle" />
              </a>

              <ul
                className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile"
                style={{
                  position: "absolute",
                  inset: "0px 0px auto auto",
                  margin: "0px",
                  transform: "translate(-16px, 38px)",
                }}
              >
                <li className="dropdown-header">
                  <h6>{auth?.name}</h6>
                  <span>{auth?.level}</span>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Link
                    to={"./painel/perfil"}
                    className="dropdown-item d-flex align-items-center"
                  >
                    <i className="bi bi-person"></i>
                    <span>Meu Perfil</span>
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <a
                    onClick={logOut}
                    className="dropdown-item d-flex align-items-center"
                    href="#"
                  >
                    <i className="bi bi-box-arrow-right"></i>
                    <span>Deslogar</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
};

export default Header;
