// eslint-disable-next-line no-useless-constructor
import { startLoad, stopLoad } from "../messages/load";
import MessageDisplay from "../messages/messageDisplay";
import logOut from "../auth/logout";
// export const ApiContext = React.createContext();

export class ApiRequest {
  tokenLocalStorage = "";
  tokenHash = "";
  login = "";

  constructor() {
    // construtor da classe
    this.tokenLocalStorage = window.localStorage.getItem("token");
  }

  async post(url, e, form = true, postData = false) {
    try {
      const msg = new MessageDisplay();
      if (!url || !e) {
        console.error("Não foram fornecidos os parâmetros necessários.");
        return false;
      }

      let body = e;
      if (form) {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        const serializedForm = Object.fromEntries(formData.entries());
        body = serializedForm;
      }

      if (postData) {
        for (let prop in postData) {
          body[prop] = postData[prop];
        }
      }

      startLoad();

      const response = await fetch(`${process.env.REACT_APP_HTTP}/${url}`, {
        method: "POST",
        timeout: 20000,
        body: JSON.stringify(body),
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          authorization: `Bearer ${this.tokenLocalStorage}`,
        },
      });

      const result = await response.json();
      if (result.error === true && result.type === "token") {
        msg.error("Login", result.data);
        return setTimeout(logOut, 1000);
      }
      stopLoad();
      return result;
    } catch (error) {
      stopLoad();
      if (!error.data) {
        error.data = "Server Desconected";
      }
      return error;
    }
  }

  async get(url, load = true) {
    const msg = new MessageDisplay();
    if (load) {
      startLoad();
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_HTTP}/${url}/`, {
        method: "GET",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          authorization: `Bearer ${this.tokenLocalStorage}`,
        },
      });
      const result = await response.json();
      if (result.error === true && result.type === "token") {
        msg.error("Login", result.data);
        return setTimeout(logOut, 1000);
      }
      stopLoad();
      return result;
    } catch (error) {
      setTimeout(logOut, 1000);
      stopLoad();
      return error;
    }
  }

  async put(url, body) {
    const msg = new MessageDisplay();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_HTTP}/${url}/${this.login}/${this.tokenHash}`,
        {
          method: "PUT",
          body: JSON.stringify(body),
          crossDomain: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            authorization: `Bearer ${this.tokenLocalStorage}`,
          },
        }
      );
      const result = await response.json();
      if (result.error === true && result.type === "token") {
        msg.error("Login", result.data);
        setTimeout(logOut, 1000);
        return;
      }
      return result;
    } catch (error) {
      return error;
    }
  }

  async delete(url) {
    const msg = new MessageDisplay();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_HTTP}/${url}/${this.login}/${this.tokenHash}`,
        {
          method: "DELETE",
          crossDomain: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            authorization: `Bearer ${this.tokenLocalStorage}`,
          },
        }
      );
      const result = await response.json();
      if (result.error === true && result.type === "token") {
        msg.error("Login", result.data);
        setTimeout(logOut, 1000);
        return;
      }
      return result;
    } catch (error) {
      return error;
    }
  }
}
