import MessageDisplay from "./messages/messageDisplay";
import { ApiRequest } from "./helpers/ApiRequest";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
// import Logo from "../assets/images/consulta.png";
import { formatName } from "./helpers/FormatData";
import { Link } from "react-router-dom";
import LoadingPage from "./components_helpers/loading";

const Login = () => {
  const [white, setWhite] = useState("");
  const [error, setError] = useState(false);
  const [view, setView] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const msg = new MessageDisplay();
  const api = new ApiRequest();
  const currentDomain = window.location.hostname;

  const whiteLabel = async () => {
    setView(false);
    const result = await api.get(`get-whitelabel/${currentDomain}`);
    console.log(result)
    if (result.error && result.data === 404) {
      setError(true);
      return msg.error(
        "Error",
        "Desculpe mais não foi possível recuperar o white label"
      );
    } else {
      setView(true);
      setWhite(result.data);
    }
  };

  const loginSubmit = async (e) => {
    const result = await api.post(`user/login`, e);

    if (result.error) {
      return msg.error("Login", result.data);
    } else {
      window.localStorage.setItem("token", result.data);
      window.localStorage.setItem("user", JSON.stringify(result.user));
      window.localStorage.setItem("loggeIn", true);
      window.location.href = "./painel/dash";
      return msg.success(
        "Sucesso",
        "Login efetudo redirecionando para o painel"
      );
    }
  };

  useEffect(() => {
    whiteLabel();
  }, []);

  if (error) {
    return (
      <>
        <p>
          Desculpe mais seu domínio não está autorizado a entrar no sistema
          entre em contato com o Administrador
        </p>
      </>
    );
  }

  if (!view) {
    return <LoadingPage />;
  }

  return (
    <>
      <Helmet>
        <title>
          {white?.name ? white?.name : "CONSULTA TOP"} - Painel de Login
        </title>
      </Helmet>
      <main
        style={{
          background:
            "linear-gradient(-130deg, rgba(227,237,255,1) 0%, rgba(255,255,255,1) 100%)",
        }}
      >
        <div className="container">
          <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">
                  <div className="d-flex justify-content-center py-4">
                    <Link
                      href="#"
                      style={{ width: "18%" }}
                      className="d-flex align-items-center w-auto"
                    >
                      <img
                        style={{ maxWidth: "280px", mixBlendMode: "multiply" }}
                        src={white.logo}
                        alt="Logo Login"
                      />
                      {/* <span className="d-none d-lg-block">NiceAdmin</span> */}
                    </Link>
                  </div>
                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="pt-4 pb-2">
                        <h5 className="card-title text-center pb-0 fs-4 fw-bold">
                          Seja Bem Vindo ao {formatName(white?.name)}!
                        </h5>
                        <p className="text-center fw-light">
                          Informe seu login e senha para acessar o Painel
                        </p>
                      </div>
                      <form
                        className="row g-3 needs-validation"
                        onSubmit={(e) => loginSubmit(e)}
                        noValidate
                      >
                        <div className="col-12">
                          <label htmlFor="yourUsername" className="form-label">
                            E-mail:
                          </label>
                          <div className="input-group has-validation">
                            {/* <span className="input-group-text" id="inputGroupPrepend">@</span> */}
                            <input
                              type="text"
                              name="email"
                              className="form-control"
                              id="yourUsername"
                              placeholder="example@email.com"
                              required
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <label htmlFor="yourPassword" className="form-label">
                            Senha:
                          </label>
                          <div className="input-group has-validation">
                            <input
                              type={showPassword ? "text" : "password"}
                              name="password"
                              className="form-control rounded-end-0"
                              id="yourPassword"
                              placeholder="********"
                              required
                            />
                            <span className="input-group-text">
                              <i
                                role="button"
                                className={
                                  showPassword
                                    ? "bi bi-eye-slash-fill"
                                    : "bi bi-eye-fill"
                                }
                                onClick={() => setShowPassword(!showPassword)}
                              ></i>
                            </span>
                          </div>
                        </div>
                        <div className="col-12" style={{ marginTop: "30px" }}>
                          <button
                            className="btn btn-primary w-100"
                            type="submit"
                          >
                            Login
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default Login;
