import { Link } from "react-router-dom";
import logOut from "../auth/logout";
import { menu } from "../../models/menu";

const Aside = () => {
  const auth = JSON.parse(localStorage.getItem("user"));
  if (!auth) {
    return logOut();
  }
  const SubMenu = (e) => {
    const view = document.getElementById(e);
    if (view) {
      if (!view.classList.contains("show")) {
        view.classList.add("show"); // Adiciona a classe "show" se não existir
      } else {
        view.classList.remove("show"); // Remove a classe "show" se já existir
      }
    }
  };

  if (!auth) {
    return null;
  }

  const menuOpen = () => {
    const body = document.querySelector("body");
    if (body) {
      if (!body.classList.contains("toggle-sidebar")) {
        body.classList.add("toggle-sidebar"); // Adiciona a classe "toggle-sidebar" se não existir
      } else {
        body.classList.remove("toggle-sidebar"); // Remove a classe "show" se já existir
      }
    }
  };

  return (
    <>
      <aside
        id="sidebar"
        className="sidebar"
        style={{
          background:
            "linear-gradient(-130deg, rgba(227,237,255,1) 0%, rgba(255,255,255,1) 100%)",
        }}
      >
        <ul className="sidebar-nav" id="sidebar-nav">
          {menu &&
            menu.map((e, index) => {
              return (
                <>
                  {(e.subMenu && e.subMenu.length) > 0 ? (
                    <>
                      {e.role && e.role.includes(auth.level) && (
                        <>
                          <li className="nav-item">
                            <a
                              onClick={() => SubMenu(`components-nav${index}`)}
                              className="nav-link"
                              data-bs-target={`#components-nav${index}`}
                              data-bs-toggle="collapse"
                              href="#"
                            >
                              <i className={e.icon}></i>
                              <span>{e.title}</span>
                              <i className="bi bi-chevron-down ms-auto "></i>
                            </a>
                            <ul
                              id={`components-nav${index}`}
                              className="nav-content collapse"
                              data-bs-parent="#sidebar-nav"
                            >
                              {e.subMenu.map((s) => {
                                return (
                                  <>
                                    {s.role && s.role.includes(auth.level) && (
                                      <>
                                        <li
                                          onClick={() => {
                                            menuOpen();
                                          }}
                                        >
                                          <Link to={s.href}>
                                            <i className="bi bi-circle-fill"></i>
                                            <span className="fw-light">
                                              {s.title}
                                            </span>
                                          </Link>
                                        </li>
                                      </>
                                    )}
                                  </>
                                );
                              })}
                            </ul>
                          </li>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {e.role && e.role.includes(auth.level) && (
                        <>
                          <li className="nav-item">
                            <Link to={e.href} className="nav-link collapsed">
                              <i className={e.icon}></i>
                              <span>{e.title}</span>
                            </Link>
                          </li>
                        </>
                      )}
                    </>
                  )}
                </>
              );
            })}
        </ul>
      </aside>
    </>
  );
};

export default Aside;
