import { useEffect, useState } from "react";
import Breadcrumb from "../components_helpers/breadcrumb";
import { ApiRequest } from "../helpers/ApiRequest";
import MessageDisplay from "../messages/messageDisplay";
import logOut from "../auth/logout";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
// import { formatDate } from "../helpers/FormatData";
import Cards from "../helpers/Cards";
import { Link } from "react-router-dom";
import { formatName } from "../helpers/FormatData";
import moment from "moment";

const Dash = () => {
  const api = new ApiRequest();
  const msg = new MessageDisplay();
  const auth = JSON.parse(localStorage.getItem("user"));

  const [datas, setDatas] = useState("");
  const [consultas, setConsultas] = useState("");
  const [typeConsult, setTypeConsult] = useState("");
  const [typequerie, setTypequerie] = useState("todas");
  const [totalConsultas, setTotalConsultas] = useState(0);
  const [mediaDia, setMediaDia] = useState(0);
  const [totalMedia, setTotalMedia] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [get, setGet] = useState(false);

  const getDash = async () => {
    setGet(false);
    const dataAtual = new Date();
    const diaAtual = dataAtual.getDate();
    const result = await api.get(`get-dash-consultas/${typequerie}`);

    if (result.error) {
      msg.error("Login", "Sua sessão expirou");
      setTimeout(logOut, 1000);
    }

    if (result.error && result.type === "token") {
      msg.error("Dashboard", result.data);
      setTimeout(logOut, 1000);
    }

    if (!result.data && result.data.length < 0) {
      msg.error("Dashboard", "Sua sessão expirou logue novamente");
      setTimeout(logOut, 1000);
    }

    const soma = result.data.reduce((acumulador, valorAtual) => {
      return acumulador + valorAtual.total;
    }, 0);
    getSaldosList();
    setTotalUsers(result.users[0]["count(*)"]);
    setTotalConsultas(soma);
    setMediaDia(soma / diaAtual);
    setTotalMedia((soma / diaAtual) * 30);
    setTypeConsult(result.queries);
    setDatas(result.data.map((e) => moment(e.date).format("DD/MM/YYYY")));
    setConsultas(result.data.map((e) => e.total));
  };

  const options = {
    chart: {
      type: "area",
    },
    title: {
      text: "Gráfico de Consultas Dia",
    },
    xAxis: {
      categories: datas,
    },
    yAxis: {
      title: {
        text: "Consultas",
      },
    },
    series: [
      {
        name: "Consultas",
        data: consultas,
      },
    ],
  };

  const onTypeConsult = (e) => {
    const value = e.target.value;
    if (value) {
      setTypequerie(value);
      setGet(true);
    }
  };

  if (get) {
    getDash();
  }

  const getSaldosList = async () => {
    //const view = document.querySelector('.messages')

    const result = await api.get(`get-dash`);
    if (result.error && result.type === "token") {
      msg.error("Não foi possível buscar os seus saldos", result.data);
      setTimeout(logOut, 2000);
    }
  };

  useEffect(() => {
    getDash();
  }, []);

  return (
    <>
      <Breadcrumb name={"DashBoard"} />
      {auth.level !== "master" && (
        <>
          <div className="alert alert-info">
            <i className="bi bi-info-circle"></i> Temos uma novidade para você
            que utiliza nossa integração via API. Agora temos um endpoint que
            realizamos o tratamento e formatação do dado antes da consulta de
            maneira automática. <Link to="/painel/api">Confira aqui!!</Link>
          </div>
        </>
      )}

      <div className="alert alert-info">
        <i className="bi bi-info-circle">
          Fizemos algumas correções nas consultas Extrato Off (Benefício e Cpf)
          pode ocorrer alguma oscilação pediamos compreenção de todos
        </i>
      </div>
      <section className="section dashboard">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <form>
              <select
                onChange={(e) => onTypeConsult(e)}
                className="form-control"
              >
                <option value={"todas"}>Todas Consultas</option>
                {typeConsult &&
                  typeConsult.length > 0 &&
                  typeConsult.map((e) => {
                    return (
                      <>
                        {e.status === 1 && (
                          <>
                            <option value={e.type}>{formatName(e.name)}</option>
                          </>
                        )}
                      </>
                    );
                  })}
              </select>
            </form>
          </div>
        </div>
        <br />

        <div className="row">
          <Cards
            col={"col-xs-12 col-md-6 col-lg-3"}
            name={"Consulta | Mês"}
            icon={"bi bi-calendar"}
            total={totalConsultas.toLocaleString("pt-BR")}
          />
          <Cards
            col={"col-xs-12 col-md-6 col-lg-3"}
            name={"Média | Dia"}
            icon={"bi bi-bag-check"}
            total={parseInt(mediaDia).toLocaleString("pt-BR")}
          />
          <Cards
            col={"col-xs-12 col-md-6 col-lg-3"}
            name={"Previsão | Mês"}
            icon={"bi bi-alarm"}
            total={parseInt(totalMedia).toLocaleString("pt-BR")}
          />
          <Cards
            col={"col-xs-12 col-md-6 col-lg-3"}
            icon={"bi bi-people"}
            name={"Total Usuários"}
            total={totalUsers.toLocaleString("pt-BR")}
          />
        </div>

        <div className="row">
          <div className="col-12 form-control border border-0">
            <br />
            <HighchartsReact highcharts={Highcharts} options={options} />
          </div>
        </div>
      </section>
    </>
  );
};

export default Dash;
