// suporte
import {
  formatarValorEmDinheiro,
  formatName,
  formatarNumeroTelefone,
  formatarCEP,
  formatarCNPJ,
} from "../../helpers/FormatData";
// componentes
import LoadingPage from "../../components_helpers/loading";
import Breadcrumb from "../../components_helpers/breadcrumb";

const Cnpj = (data) => {
  console.log('asdfasdfafsd')
  // vars
  const obj = data?.data?.d?.CONSULTA;

  if (!obj) {
    return <LoadingPage />;
  }
  // funcs
  const handlePrintToPDF = () => {
    const element0 = document.querySelector("header");
    const element1 = document.querySelector(".pagetitle");
    element0.style.setProperty("display", "none", "important");
    element1.style.setProperty("display", "none", "important");

    // Chamar a função de impressão
    window.print();

    element0.style.removeProperty("display");
    element1.style.removeProperty("display");
  };

  const cadastrals = obj?.CADASTRAIS;
  const contacts = obj?.TELEFONES;
  const enderecos = obj.ENDERECOS;
  const emails = obj?.EMAILS;
  const situation = obj?.SITUACAOCADASTRAL;

  return (
    <>
      <Breadcrumb
        name={`CONSULTA ${formatarCNPJ(obj.CADASTRAIS?.CNPJ)}`}
        button={[
          {
            name: "Imprimir Consulta",
            colorBtn: "primary",
            click: handlePrintToPDF,
            value: data,
            view: true,
          },
        ]}
      />
      <section className="section contact">
        <div className="row gy-4">
          <div className="col-xl-12">
            <div className="row">
              <div className="col-lg-12">
                <div className="info-box card">
                  {/* <i className="ri-home-5-fill"></i> */}
                  <h3>Dados da Empresa</h3>
                  <div className="row">
                    <div className="col-lg-6">
                      <p>
                        <b>Nome Fantasia : </b>
                        {formatName(cadastrals?.NOME_FANTASIA) || "Indefinido"}
                      </p>
                      <p>
                        <b>Razão Social : </b>
                        {formatName(cadastrals?.RAZAO) || "Indefinido"}
                      </p>
                      <p>
                        <b>CNPJ : </b>
                        {formatarCNPJ(cadastrals?.CNPJ) || "Indefinido"}
                      </p>
                      <p>
                        <b>Capital Social : </b>
                        {formatarValorEmDinheiro(cadastrals?.CAPITALSOCIAL) || "Indefinido"}
                      </p>
                      <p>
                        <b>Data Abertura : </b>
                        {cadastrals?.DATA_ABERTURA || "Indefinido"}
                      </p>
                      <p>
                        <b>Descrição CNAE : </b>
                        {cadastrals?.DESC_CNAE || "Indefinido"}
                      </p>
                      <p>
                        <b>Faturamento PRES : </b>
                        { formatarValorEmDinheiro(cadastrals?.FATURAMENTOPRESUMIDO) || "Indefinido" }
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <p>
                        <b>Inscrição Estadual : </b>
                        {cadastrals?.INSCRICAO_ESTADUAL || "Indefinido"}
                      </p>
                      <p>
                        <b>Matriz : </b>
                        {cadastrals?.MATRIZ || "Indefinido"}
                      </p>
                      <p>
                        <b>Risco Score : </b>
                        {cadastrals?.MENSAGEMSCORE || "Indefinido"}
                      </p>
                      <p>
                        <b>Porte : </b>
                        {formatName(cadastrals?.PORTE) || "Indefinido"}
                      </p>
                      <p>
                        <b>Qtd. Funcionários : </b>
                        {cadastrals?.QTDEFUNCIONARIOS || "Indefinido"}
                      </p>
                      <p>
                        <b>Filiais : </b>
                        {cadastrals?.QTDE_FILIAIS || "Indefinido"}
                      </p>
                      <p>
                        <b>Score : </b>
                        {cadastrals?.SCORE || "Indefinido"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="info-box card">
                  {/* <i className="bi bi-telephone"></i> */}
                  <h3>contacts</h3>
                  {contacts && contacts.length > 0 ? (
                    contacts.map((e, index) => {
                      return (
                        <>
                          {index > 0 && (
                            <>
                              <hr />
                            </>
                          )}
                          <p>
                            <b>Tel {index} : </b>{" "}
                            {formatarNumeroTelefone(e?.DDD + e?.TELEFONE) ||
                              "Indefinido"}{" "}
                            <br />
                            <b>Flag Hot : </b> {e?.FLHOT || "Indefinido"} <br />{" "}
                            <b>Operadora : </b>{" "}
                            {formatName(e?.OPERADORA) || "Indefinido"} <br />{" "}
                            <b>Procon : </b> {e?.PROCON}
                          </p>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <p>Não existem Telefones</p>
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="info-box card">
                  {/* <i className="bi bi-geo-alt"></i> */}
                  <h3>Endereços</h3>
                  {enderecos && enderecos.length > 0 ? (
                    enderecos.map((e, index) => {
                      return (
                        <>
                          {index > 0 && (
                            <>
                              <hr />
                            </>
                          )}
                          <p>
                            <b>CEP {index} : </b>{" "}
                            {formatarCEP(e?.CEP) || "Indefinido"}
                          </p>
                          <p>
                            <b>Cidade : </b>{" "}
                            {formatName(e?.CIDADE) || "Indefinido"}
                          </p>
                          <p>
                            <b>Bairro : </b>{" "}
                            {formatName(e?.BAIRRO) || "Indefinido"}
                          </p>
                          <p>
                            <b>Complemento : </b>{" "}
                            {e.COMPLEMENTO || "Indefinido"}
                          </p>
                          <p>
                            <b>Logradouro : </b>{" "}
                            {formatName(e?.LOGRADOURO) || "Indefinido"}
                          </p>
                          <p>
                            <b>Número : </b> {e.NUMERO || "Indefinido"}
                          </p>
                          <p>
                            <b>UF.</b> {e.UF || "Indefinido"}
                          </p>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <p>Não existem Endereços</p>
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="info-box card">
                  {/* <i className="bi bi-envelope"></i> */}
                  <h3>Emails</h3>
                  {emails && emails.length > 0 ? (
                    emails.map((e, index) => {
                      return (
                        <>
                          {index > 0 && (
                            <>
                              <hr />
                            </>
                          )}
                          <p>
                            <b>Email {index} : </b> {e.EMAIL || "Indefinido"}
                          </p>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <p>Não existem Emails</p>
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="info-box card">
                  {/* <i className="bi bi-clock"></i> */}
                  <h3>Situação</h3>
                  <p>
                    <b>Situação : </b>{" "}
                    {formatName(situation?.DESCRICAO) || "Indefinido"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cnpj;
