import { useEffect, useState } from "react";
import Breadcrumb from "../components_helpers/breadcrumb";
import { ApiRequest } from "../helpers/ApiRequest";
import MessageDisplay from "../messages/messageDisplay";
import { formatarNumeroTelefone } from "../helpers/FormatData";

const WhatsApp = () => {
  const api = new ApiRequest();
  const msg = new MessageDisplay();
  const [whats, setWhatsapp] = useState("");

  const getWhats = async () => {
    const result = await api.get(`get-whats/true`);
    if (result.error === true) {
      return msg.error(
        "Whats",
        "Desculpe mais não foi possível localizar seu whatsapp"
      );
    }
    setWhatsapp(result.data.whatsapp);
  };

  const updateWhatsApp = async (e) => {
    const result = await api.post(`add-whats`, e);
    if (result.error == true) {
      return msg.error("WhatsApp", result.data);
    }
    getWhats();
  };

  useEffect(() => {
    getWhats();
  }, []);

  return (
    <>
      <Breadcrumb name={"WhatsApp"} />
      <section className="section">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title fw-bold">Atualizar WhatsApp</h5>
                <form onSubmit={(e) => updateWhatsApp(e)} className="row g-3">
                  <div className="col-12">
                    <label htmlFor="inputNanme4" className="form-label fw-light">
                      Digite seu número:{" "}
                    </label>
                    <input
                      type="text"
                      defaultValue={formatarNumeroTelefone(whats)}
                      className="form-control border border-info"
                      name="whatsapp"
                      placeholder="(99) 99999-9999"
                    />
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn btn-success">
                      Atualizar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhatsApp;
