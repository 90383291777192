// React
import React from "react";
// componenetes
import Breadcrumb from "../../components_helpers/breadcrumb";
import LoadingPage from "../../components_helpers/loading";
import TryAgain from "../../components_helpers/tryAgain";

// suportes
import {
  // formatDate,
  formatarValorEmDinheiro,
  formatName,
} from "../../helpers/FormatData";

const In100 = ({ data }) => {
  // vars
  const obj = data;
  console.log('informação: ', obj);
  // funcs

  // const converterParaPadraoAmericano = (data) => {
  //   if (data) {
  //     const partes = data.split('-');
  //     if (partes.length === 3) {
  //       const dataAmericana = partes[2] + '-' + partes[1] + '-' + partes[0]; // Mudança aqui
  //       return dataAmericana;
  //     }
  //   }
  //   return data;
  // };


  const handlePrintToPDF = () => {
    const element0 = document.querySelector("header");
    const element1 = document.querySelector(".pagetitle");
    element0.style.setProperty("display", "none", "important");
    element1.style.setProperty("display", "none", "important");
    window.print();
    element0.style.removeProperty("display");
    element1.style.removeProperty("display");
  };

  const meioPagto = obj?.meioPagamento
  const agencia = obj?.agencia

  if (!obj?.nome) {
    return <TryAgain />
  }

  if (!obj) {
    return <LoadingPage />;
  }

  return (
    <>
      <Breadcrumb
        name={`CONSULTA ${obj?.beneficio}`}
        button={[
          {
            name: "Imprimir Consulta",
            colorBtn: "primary",
            click: handlePrintToPDF,
            value: data,
            view: true,
          },
        ]}
      />
      <section className="section contact">
        <div className="row gy-4">
          <div className="col-xl-12">
            <div className="row">
              <div style={{ textAlign: "center" }}>
                <h4>Demostrativo Simples para Conferência</h4>
                <h3>
                  <b>IN100</b>
                </h3>
                <p
                  style={{
                    width: "100%",
                    padding: "1.5px",
                    backgroundColor: "#ccc",
                  }}
                ></p>
                <p>{formatName(obj?.nome) || "Não Informado"}</p>
              </div>
              <div className="info-box card">
                <h3>Benefício</h3>

                <div className="row col-12">
                  <table className="table" style={{ fontSize: '0.85em', borderColor: '#fff', marginLeft: '0.5em' }}>
                    <tbody>
                      <tr>
                        <td colSpan="2" style={{ padding: '3px', color: 'blue', fontWeight: 'bold' }}>
                          <b>Especie: </b>{obj.especie || 'Não informado'}
                        </td>
                      </tr>
                      <tr>
                        <td className="col-6" style={{ padding: '3px' }}>
                          <b>CPF: </b> {obj?.cpf || 'Não Informado'}
                        </td>
                        <td className="col-6" style={{ padding: '3px' }}>
                          <b>Possui Procurador: </b>{obj?.possuiProcurador ? "SIM" : 'NÃO'}
                          <br></br>
                          <b>Possui Representante Legal: </b>{obj?.possuiRepresentante ? "SIM" : 'NÃO'}
                        </td>
                      </tr>
                      <tr>
                        <td className="col-6" style={{ padding: '3px' }}>
                          <b>Nº Benefício: </b> {obj?.beneficio || 'Não Informado'}
                        </td>
                        <td className="col-6" style={{ padding: '3px' }}><b>Pensão Alimentícia: </b>{obj?.pensaoAlimenticia ? "SIM" : "NÃO"}</td>

                      </tr>
                      <tr>

                        <td className="col-6" style={{ padding: '3px' }}>
                          <b>Data Nasc.: </b>
                          {obj?.dataNascimento ?
                            obj?.dataNascimento
                            :
                            'Não encontrado'
                          }
                        </td>
                        <td className="col-6" style={{ padding: '3px' }}><b>Situação: </b> {obj?.situacao || 'Não Informado'}</td>
                      </tr>
                      <tr>
                        <td className="col-6" style={{ padding: '3px' }}><b>DDB: </b>  {obj?.ddb ? obj?.ddb : "Não informado"} </td>
                        <td className="col-6" style={{ padding: '3px' }}><b>Bloqueado: </b>{(obj?.blo1_emp === false || obj?.blo1_emp === 'false') ? "NÃO" : "SIM"}</td>
                      </tr>
                      <tr>
                        <td className="col-6" style={{ padding: '3px' }}><b>Base de Cálculo: </b> {formatarValorEmDinheiro(obj?.baseCalculoMargemConsignavel) || "Não informado"}</td>
                        <td className="col-6" style={{ padding: '3px' }}><b>Valor Benefício: </b>{formatarValorEmDinheiro(obj?.valor_beneficio) || "Não informado"}</td>
                      </tr>
                      <tr>
                        <td className="col-6" style={{ padding: '3px' }}><b>Meio: </b> {meioPagto || 'Não Informado'}</td>
                        <td className="col-6" style={{ padding: '3px' }}><b>Margem: </b>{formatarValorEmDinheiro(obj?.margem_35) || "Não informado"}</td>
                      </tr>
                      <tr>
                        <td className="col-6" style={{ padding: '3px' }}><b>Banco: </b> {obj?.banco || "Não informado"}</td>
                        <td className="col-6" style={{ padding: '3px' }}><b>Margem RCC: </b>  {formatarValorEmDinheiro(obj?.margem_rcc) || "Não informado"}</td>
                      </tr>
                      <tr>
                        <td className="col-6" style={{ padding: '3px' }}><b>Agência: </b> {agencia || 'Não Informado'}</td>
                        <td className="col-6" style={{ padding: '3px' }}><b>Margem RMC: </b>  {formatarValorEmDinheiro(obj?.margem_rmc) || "Não informado"}</td>
                      </tr>
                      <tr>
                        <td className="col-6" style={{ padding: '3px' }}><b>Conta Corrente: </b> {obj?.conta || 'Não Informado'}</td>
                        <td className="col-6" style={{ padding: '3px' }}><b>Quantidade Empréstimos: </b>
                          {
                            obj?.quantidade_emprestimo
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className="col-6" style={{ padding: '3px' }}><b>UF: </b> {obj?.uf_ben || 'Não Informado'}</td>
                        <td className="col-6" style={{ padding: '3px' }}><b>Permite Empréstimo: </b> {obj?.permite_emp ? "SIM" : "NÃO"}</td>
                      </tr>

                    </tbody>
                  </table>
                </div>

                <br />
                {/* <div className="row">
                  <div className="col-lg-6">
                    <p>
                      <b>Possui Representante Legal Procurador :</b>{" "}
                      {obj?.possuiRepresentanteLegalProcurador === false
                        ? "Não"
                        : "Sim"}
                    </p>
                    <p>
                      <b>Tipo de Conta :</b> {obj?.tipo_conta || "Não informado"}
                    </p>
                    <p>
                      <b>Valor Benefício :</b>{" "}
                      {formatarValorEmDinheiro(obj?.valor_beneficio) ||
                        "Não informado"}
                    </p>
                    <p>
                      <b>Base de Calculo :</b>{" "}

                    </p>
                  </div>
                  <div className="col-lg-6">
                    <p>
                      <b>Bloqueado :</b>{" "}
                      {obj?.blo1_emp === false ? "Não" : "Sim"}
                    </p>
                    <p>
                      <b>Permite Empréstimo :</b>{" "}
                      {obj?.permite_emp === false ? "Não" : "Sim"}
                    </p>
                    <p>
                      <b>DDB :</b>{" "}
                      {obj?.ddb ? formatDateNew(obj?.ddb) : "Não informado"}
                    </p>
                    <p>
                      <b>Data Nascimento :</b>{" "}
                      {formatDateNew(obj?.dataNascimento) || "Não informado"}
                    </p>
                    <p>
                      <b>Margem :</b>{" "}

                    </p>
                    <p>
                      <b>Margem RCC :</b>{" "}

                    </p>
                    <p>
                      <b>Margem RMC :</b>{" "}

                    </p>
                    <p>
                      <b>Quantidade Empréstimos :</b>{" "}

                    </p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default In100;
