import { useParams } from "react-router-dom";
import Breadcrumb from "../components_helpers/breadcrumb";
import { ApiRequest } from "../helpers/ApiRequest";
import MessageDisplay from "../messages/messageDisplay";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  nameAndSurname,
  checkEmail,
  checkPassword,
  removeExtraSpace,
  countWords,
} from "../helpers/regEx";
import LoadingPage from "../components_helpers/loading";

const EditUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState(""); 
  const [password_confirm, setPasswordConfirm] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const api = new ApiRequest();
  const msg = new MessageDisplay();

  const [user, setUser] = useState("");

  const edidUserView = async () => {
    const result = await api.get(`user/edit/${id}`);
    if (result.error || !result) {
      return msg.error("Usuário", result.data);
    }

    setName(result.data.name);
    setEmail(result.data.email);
    setUser(result.data);
  };

  const updateUser = async (event) => {
    event.preventDefault();

    setName(removeExtraSpace(name));
    setEmail(removeExtraSpace(email));

    let wordCount = countWords(name, 15);

    if (!nameAndSurname(name))
      return msg.error("Nome inválido. Deve conter nome e/ou sobrenomes, com a primeira letra de cada nome em maiúscula. Ex: Mario Da Silva ou Mario Silva");

    if (wordCount === false)
      return msg.error("Uma ou mais palavras excedem o limite de caracteres permitidos no campo nome.");
        
    if (wordCount > 7)
      return msg.error("O nome Deve ter no máximo 7 palavras");

    if (!email || (email && !email.trim().length))
      return msg.error('O campo de E-mail está vazio. Por favor, preencha-o.');  
  
    if (!checkEmail(email))
      return msg.error('O endereço de E-mail informado é inválido. Por favor, verifique e tente novamente.');

    if (checkPassword(password) || password.length < 6)
      return msg.error('A senha devem conter pelo menos uma letra maiúscula, um número e ser maior que 6 caracteres.');

    if (!password || !password.trim().length || password !== password_confirm)
      return msg.error('Por favor, confirme a senha, ela deve ser idêntica à senha anterior.');

    const result = await api.post(`user/update`, event);
    
    if (result.error || !result) {
      return msg.error("Usuário", result.data);
    }

    navigate("/painel/usuarios");
    return msg.success("Usuário", "Cadastro realizado com sucesso");
  };

  useEffect(() => {
    edidUserView();
  }, []);

  if (!user) {
    return <LoadingPage />;
  }

  return (
    <>
      <Breadcrumb name={"Editar Usuário"} />
      <section className="section">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Editar Usuário</h5>
                <form onSubmit={(e) => updateUser(e)} className="row g-3">
                  <div className="col-12">
                    <input
                      defaultValue={user?.id}
                      type="hidden"
                      className="form-control"
                      placeholder="Nome"
                      name="id"
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="inputNanme4" className="form-label">
                      Nome
                    </label>
                    <input
                      defaultValue={name}
                      type="text"
                      className="form-control"
                      placeholder="Nome do Usuário"
                      name="name"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="inputNanme4" className="form-label">
                      E-mail
                    </label>
                    <input
                      defaultValue={email}
                      type="text"
                      className="form-control"
                      placeholder="E-mail"
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="inputNanme4" className="form-label">
                      Senha
                    </label>
                    <input
                      type={showPassword ? 'text' : 'password'}
                      className="form-control"
                      placeholder="Senha"
                      name="password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="inputNanme4" className="form-label">
                      Repetir Senha
                    </label>
                    <input
                      type={showPassword ? 'text' : 'password'}
                      className="form-control"
                      placeholder="Repetir Senha"
                      name="password_confirm"
                      onChange={(e) => setPasswordConfirm(e.target.value)}
                    />
                  </div>
                  <div className="col-12">
                      <div htmlFor="inputNanme4" className="form-label">
                          <span className="btn btn-primary" onClick={() => showPassword ? setShowPassword(false) : setShowPassword(true)}>{showPassword ? 'Esconder' : 'Mostrar'} as senhas</span>
                      </div>
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn btn-primary">
                      Atualizar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditUser;
