import Breadcrumb from "../../components_helpers/breadcrumb";
import moment from 'moment'
import {
  formatarValorEmDinheiro,
  formatCPF,
  formatRG,
  // formatDate,
  formatName,
  formatarCEP,
} from "../../helpers/FormatData";

const Siape = ({ data }) => {
  const obj = data;
  const contratos = obj?.RUBRICAS;

  const handlePrintToPDF = () => {
    const element0 = document.querySelector("header");
    const element1 = document.querySelector(".pagetitle");
    element0.style.setProperty("display", "none", "important");
    element1.style.setProperty("display", "none", "important");

    // Chamar a função de impressão
    window.print();

    element0.style.removeProperty("display");
    element1.style.removeProperty("display");
  };

  return (
    <>
      <Breadcrumb
        name={`MATRICULA (${obj?.MATRICULA})`}
        button={[
          {
            name: "Imprimir Consulta",
            colorBtn: "primary",
            click: handlePrintToPDF,
            value: data,
            view: true,
          },
        ]}
      />
      <section className="section contact">
        <div className="row gy-4">
          <div className="col-xl-12">
            <div className="row">
              <div style={{ textAlign: "center" }}>
                <h4>Histórico de</h4>
                <h3>
                  <b>Empréstimo Consignado</b>
                </h3>
                <p
                  style={{
                    width: "100%",
                    padding: "1.5px",
                    backgroundColor: "#ccc",
                  }}
                ></p>
                <p>{obj?.NOME || "Não Informado"}</p>
              </div>
              <div className="info-box card">
                <h3>Benefício</h3>
                <div className="row">
                  <div className="col-4">
                    <p>
                      <b>Matrícula : </b>
                      {obj?.MATRICULA}
                    </p>
                    <p>
                      <b>CPF : </b>
                      {formatCPF(obj?.CPF)}
                    </p>
                    <p>
                      <b>RG : </b>
                      {obj?.RG > 3
                        ? formatRG(obj?.RG)
                        : obj?.RG || "Indefinido"}
                    </p>
                    <p>
                      <b>Data de Nascimento : </b>
                      {moment(obj?.NASC).format('DD/MM/YYYY')}
                    </p>
                    <p>
                      <b>E-mail : </b>
                      {obj?.EMAIL || "Indefinido"}
                    </p>
                    <p>
                      <b>Idade : </b>
                      {obj?.IDADE}
                    </p>
                    <p>
                      <b>Sexo : </b>
                      {formatName(obj?.SEXO)}
                    </p>
                  </div>
                  <div className="col-4">
                    <p>
                      <b>Cidade : </b>
                      {formatName(obj?.CIDADE)}
                    </p>
                    <p>
                      <b>UF : </b>
                      {obj?.UF}
                    </p>
                    <p>
                      <b>CEP : </b>
                      {formatarCEP(obj?.CEP) || "Indefinido"}
                    </p>
                  </div>
                  <div className="col-4">
                    <p>
                      <b>Classe : </b>
                      {obj?.CLASSE || "Indefinido"}
                    </p>
                  </div>
                </div>

                <br />
              </div>
              <div className="info-box card">
                <h3>Contra Cheque</h3>
                <div className="row">
                  <div className="col-6">
                    <p>
                      <b>Regime Júridico : </b>
                      {obj?.RJUR || "Indefinido"}
                    </p>
                    <p>
                      <b>Instrutor : </b>
                      {obj?.INSTITUIDOR || "Indefinido"}
                    </p>
                    <p>
                      <b>Situação : </b>
                      {formatName(obj?.SIT) || "Indefinido"}
                    </p>
                    <p>
                      <b>Orgão : </b>
                      {obj?.ORGAO || "Indefinido"}
                    </p>
                  </div>
                  <div className="col-6">
                    <p>
                      <b>Valor Bruto : </b>
                      {formatarValorEmDinheiro(
                        obj?.RESUMO_FINANCEIRO?.RENDBRUTO
                      ) || "Indefinido"}
                    </p>
                    <p>
                      <b>Margem : </b>
                      {formatarValorEmDinheiro(
                        obj?.RESUMO_FINANCEIRO?.MARGEMDESALDO
                      ) || "Indefinido"}
                    </p>
                    <p>
                      <b>Valor Liquido : </b>
                      {formatarValorEmDinheiro(
                        obj?.RESUMO_FINANCEIRO?.TOTLIQ
                      ) || "Indefinido"}
                    </p>
                    <p>
                      <b>Margem Cartão : </b>
                      {formatarValorEmDinheiro(
                        obj?.RESUMO_FINANCEIRO?.SALDO5
                      ) || "Indefinido"}
                    </p>
                    <p>
                      <b>Total Desconto : </b>
                      {formatarValorEmDinheiro(
                        obj?.RESUMO_FINANCEIRO?.UTIL70
                      ) || "Indefinido"}
                    </p>
                  </div>
                </div>
                <br />
              </div>
              <div className="info-box card">
                <h3>Contratos</h3>
                <div className="row">
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <td colSpan="9">Contratos Ativos e Suspensos</td>
                      </tr>
                      <tr>
                        <td>Contrato</td>
                        <td>Banco</td>
                        <td>Prazo</td>
                        <td>Vl. Parcela</td>
                        <td>Vl. Emprestado</td>
                      </tr>
                      {contratos && contratos.length > 0
                        ? contratos.map((e) => {
                            return (
                              <>
                                <tr style={{ textAlign: "center" }}>
                                  <td>{e?.CONTRATO}</td>
                                  <td>{e?.RUBRICA_DESCRICAO}</td>
                                  <td>{e?.PRAZO}</td>
                                  <td>
                                    {formatarValorEmDinheiro(e?.VALOR_PMT)}
                                  </td>
                                  <td>
                                    {formatarValorEmDinheiro(
                                      e?.VALOR_PMT * e?.PRAZO
                                    )}
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        : ""}
                    </tbody>
                  </table>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Siape;
