const TryAgain = () => {
  return (
    <div
      style={{ minHeight: "100vh" }}
      className="text-center d-flex flex-column justify-content-center align-items-center"
    >
      <p className="fw-bold fs-3">Tente novamente!</p>
      <figure>
        <img
          width="200px"
          src="https://cdn.dribbble.com/users/251873/screenshots/9288094/13539-sign-for-error-or-explanation-alert.gif"
          alt="Loading Gif"
        />
      </figure>
    </div>
  );
};
export default TryAgain;
