import Breadcrumb from "../../components_helpers/breadcrumb";
import {
  formatarNumeroTelefone,
  formatCPF,
  formatName,
} from "../../helpers/FormatData";
import LoadingPage from '../../components_helpers/loading'

const Telefone = (data) => {
  const obj = data.data;
  if (!obj) {
    return <LoadingPage/>
  }

  const handlePrintToPDF = () => {
    const element0 = document.querySelector("header");
    const element1 = document.querySelector(".pagetitle");
    element0.style.setProperty("display", "none", "important");
    element1.style.setProperty("display", "none", "important");
    window.print();

    element0.style.removeProperty("display");
    element1.style.removeProperty("display");
  };
  const contatos = obj.phones;

  return (
    <>
      <Breadcrumb
        name={`Consulta (${formatCPF(obj?.document)})`}
        button={[
          {
            name: "Imprimir Consulta",
            colorBtn: "primary",
            click: handlePrintToPDF,
            value: data,
            view: true,
          },
        ]}
      />
      <section className="section contact">
        <div className="row gy-4">
          <div className="col-xl-12">
            <div className="row">
              <div className="col-lg-12">
                <div className="info-box card">
                  <i className="bi bi-telephone"></i>
                  <h3>Contatos</h3>
                  {contatos && contatos.length > 0 ? (
                    contatos.map((e, index) => {
                      return (
                        <>
                          {index > 0 && (
                            <>
                              <hr />
                            </>
                          )}
                          <p>
                            <b>Tel {index} : </b>
                            {formatarNumeroTelefone(e?.numeroLinha) ||
                              "Indefinido"}
                            <br />
                            <b>Classificação : </b>
                            {e?.classificacao || "Indefinido"} <br />
                            <b>Nota : </b> {e?.fone_nota || "Indefinido"}
                            <br />
                            <b>Tipo : </b> {formatName(e?.tipo) || "Indefinido"}
                            <br />
                            <b>WhatsApp : </b> {formatName(e?.whatsapp) || "Indefinido"}
                            <br />
                            <b>Documento: </b> {formatCPF(e.doc) || "Indefinido"}
                          
                          </p>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <p>Não existem Telefones</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Telefone;
