import Breadcrumb from "../../components_helpers/breadcrumb";
import {
  formatCPF,
  formatDateYYYYMMDD,
  formatName,
  formatarNumeroTelefone,
  formatarCEP,
} from "../../helpers/FormatData";
import LoadingPage from '../../components_helpers/loading'

const FoneWhats = (data) => {
  const obj = data.data;
  console.log(obj)
  const cadastrais = obj.CADASTRO;
  const celulares = obj.CELULARES;
  const enderecos = obj.ENDERECOS;
  const emails = obj.EMAILS;
  if (!obj) {
    return <LoadingPage />
  }

  const handlePrintToPDF = () => {
    const element0 = document.querySelector("header");
    const element1 = document.querySelector(".pagetitle");
    element0.style.setProperty("display", "none", "important");
    element1.style.setProperty("display", "none", "important");

    // Chamar a função de impressão
    window.print();

    element0.style.removeProperty("display");
    element1.style.removeProperty("display");
  };

  return (
    <>
      <Breadcrumb
        name={`Consulta ${formatCPF(cadastrais?.cpf)}`}
        button={[
          {
            name: "Imprimir Consulta",
            colorBtn: "primary",
            click: handlePrintToPDF,
            value: data,
            view: true,
          },
        ]}
      />
      <section className="section contact">
        <div className="row gy-4">
          <div className="col-xl-12">
            <div className="row">
              <div className="col-lg-12">
                <div className="info-box card">
                  {/* <i className="ri-home-5-fill"></i> */}
                  <h3>Dados Cadastrais</h3>
                  <div className="row">
                    <div className="col-lg-6">
                      <p>
                        <b>Nome : </b>
                        {formatName(cadastrais?.NOME) || "Indefinido"}
                      </p>
                      <p>
                        <b>CPF : </b>
                        {formatCPF(cadastrais?.CPF) || "Indefinido"}
                      </p>
                      <p>
                        <b>CPF Mãe : </b>
                        {formatCPF(cadastrais?.DESCRICAO_CBO) || "Indefinido"}
                      </p>
                      <p>
                        <b>Nome Mãe : </b>
                        {formatName(cadastrais?.GERACAO) || "Indefinido"}
                      </p>
                      <p>
                        <b>Nome Pai: </b>
                        {formatName(cadastrais?.IDADE) || "Indefinido"}
                      </p>
                      <p>
                        <b>Nascimento Mãe : </b>
                        {formatDateYYYYMMDD(cadastrais?.NASC) || "Indefinido"}
                      </p>
                      <p>
                        <b>Sexo : </b>
                        {cadastrais?.SEXO}
                      </p>
                      <p>
                        <b>Dívida Ativa : </b>
                        {cadastrais?.NOME_MAE ||
                          "Indefinido"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="info-box card">
                  {/* <i className="bi bi-telephone"></i> */}
                  <h3>Contatos</h3>
                  {celulares && celulares.length > 0 ? (
                    celulares.map((e, index) => {
                      return (
                        <>
                          {index > 0 && (
                            <>
                              <hr />
                            </>
                          )}
                          <p>
                            <b>Tel {index} : </b>
                            {formatarNumeroTelefone(e?.DDDCEL + e?.CEL) ||
                              "Indefinido"}
                          </p>
                          <p>
                            <b>Flag WhatsApp : </b>{" "}
                            {e?.FLWHATSAPP || "Indefinido"}
                          </p>
                          <p>
                            <b>Procon : </b> {e?.PROCON || "Indefinido"}
                          </p>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <p>Não existem Telefones</p>
                    </>
                  )}
                </div>
              </div>

              <div className="col-lg-6">
                <div className="info-box card">
                  {/* <i className="bi bi-geo-alt"></i> */}
                  <h3>Endereços</h3>
                  {enderecos && enderecos.length > 0 ? (
                    enderecos.map((e, index) => {
                      return (
                        <>
                          {index > 0 && (
                            <>
                              <hr />
                            </>
                          )}
                          <p>
                            <b>Cep {index} : </b>{" "}
                            {formatarCEP(e?.CEP) || "Indefinido"}
                          </p>
                          <p>
                            <b>Cidade : </b>{" "}
                            {formatName(e?.CIDADE) || "Indefinido"}
                          </p>
                          <p>
                            <b>Bairro : </b>{" "}
                            {formatName(e?.BAIRRO) || "Indefinido"}
                          </p>
                          <p>
                            <b>Complemento : </b>{" "}
                            {formatName(e?.COMPLEMENTO) || "Indefinido"}
                          </p>
                          <p>
                            <b>Logradouro : </b>{" "}
                            {formatName(e?.LOGRADOURO) || "Indefinido"}
                          </p>
                          <p>
                            <b>Número : </b> {e.NUMERO || "Indefinido"}
                          </p>
                          <p>
                            <b>UF : </b> {e.UF || "Indefinido"}
                          </p>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <p>Não existem Endereços</p>
                    </>
                  )}
                </div>
              </div>

              <div className="col-lg-4">
                <div className="info-box card">
                  {/* <i className="bi bi-envelope"></i> */}
                  <h3>Emails</h3>
                  {emails && emails.length > 0 ? (
                    emails.map((e, index) => {
                      return (
                        <>
                          {index > 0 && (
                            <>
                              <hr />
                            </>
                          )}
                          <p>
                            <b>Email {index} : </b> {e?.email || "Indefinido"}
                          </p>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <p>Não existem Emails</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FoneWhats;
