import { useParams } from "react-router-dom"
import { ApiRequest } from "../helpers/ApiRequest"
import MessageDisplay from "../messages/messageDisplay";
import { useEffect, useState } from "react";
import Cnpj from "./models/cnpj";
import Online from "./models/online";
import Pessoa from "./models/pessoa";
import Telefone from "./models/telefone";
import Fgts from "./models/fgts";
import Offline from "./models/offiline";
import ErrorConsult from "./models/error";
import In100 from "./models/in100";
import Siape from "./models/siape";
import OfflineCpf from "./models/offline_cpf";
import In100Plus from "./models/in100_plus";
import FoneWhats from "./models/fone_whats_obg";

const Consulta = () => {
    const { id, type } = useParams()
    const api = new ApiRequest();
    const msg = new MessageDisplay();
    const [data, setData] = useState('')

    const viewConsult = async () => {
        const result = await api.post(`extrato/consult`, { document: id, type: type }, false).catch(() => { })
        if (result.error === true || !result.data) {
            return msg.error('Consulta', result.data)
        }
        setData(result.data)
    }


    const switchConsultView = () => {
        switch (type) {
            case 'saldo_in100':
                return <In100 data={data} />
            case 'saldo_extrato_online':
                return <Online data={data} />
            case 'saldo_extrato_off':
                return <Offline data={data} />
            case 'saldo_siape':
                return <Siape data={data} />
            case 'saldo_fgts':
                return <Fgts data={data} />
            case 'saldo_phone':
                return <Telefone data={data} />
            case 'saldo_consulta_geral':
                return <Pessoa data={data} />
            case 'saldo_consulta_geral_cnpj':
                return <Cnpj data={data} />
            case 'saldo_extrato_offline_cpf':
                return <OfflineCpf data={data} />
            case 'saldo_in100_plus':
                return <In100Plus data={data} />
            case 'saldo_phone_cel_obg':
                return <FoneWhats data={data} />
            default:
                return <ErrorConsult />
        }
    }

    useEffect(() => {
        viewConsult()
    }, [])


    return (
        <>
            {switchConsultView()}
        </>
    )
}

export default Consulta