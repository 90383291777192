// react
import React from "react";
// componenentes
import Breadcrumb from "../../components_helpers/breadcrumb";
import LoadingPage from "../../components_helpers/loading";
import TryAgain from "../../components_helpers/tryAgain";
import moment from "moment";
// suportes
import {
  // formatDate,
  formatarValorEmDinheiro,
  formatName,
} from "../../helpers/FormatData";

const Offline = (data) => {
  // vars
  const componentRefs = React.createRef();
  const obj = data?.data?.beneficio;
  console.log(obj);
  const contratos = obj?.contratosEmprestimo;
  const cartaoRmc = obj?.contratosCartao;
  const cartaoRcc = obj?.contratosRcc;

  const utilizada =
    contratos && contratos.length > 0
      ? contratos.reduce((a, b) => a + b.valorParcela, 0)
      : 0;
  console.log(utilizada);
  const margemExtrapolada =
    obj?.margem?.baseCalculoMargemConsignavel * 0.35 - utilizada > 0
      ? 0
      : obj?.margem?.baseCalculoMargemConsignavel * 0.35 - utilizada;
  // funcs
  const handlePrintToPDF = () => {
    const element0 = document.querySelector("header");
    const element1 = document.querySelector(".pagetitle");
    element0.style.setProperty("display", "none", "important");
    element1.style.setProperty("display", "none", "important");
    // Chamar a função de impressão
    window.print();
    element0.style.removeProperty("display");
    element1.style.removeProperty("display");
  };

  if (!obj?.nome) {
    return <TryAgain />;
  }

  if (!obj) {
    return <LoadingPage />;
  }

  const converterParaPadraoAmericano = (data) => {
    if (data) {
      const partes = data.split("-");

      const dataAmericana = partes[2] + "-" + partes[1] + "-" + partes[0]; // Mudança aqui

      return dataAmericana;
    }
    return data;
  };

  const isDateValid = (dateString) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;

    if (!regex.test(dateString)) return false;

    const parts = dateString.split("-");
    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const day = parseInt(parts[2], 10);
    const date = new Date(year, month, day);
    return (
      date.getFullYear() === year &&
      date.getMonth() === month &&
      date.getDate() === day
    );
  };

  const dataFotmatSubsTring = (data) => {
    data = data.toString();
    const ano = data.substring(0, 4);
    const mes = data.substring(4, 6);
    const dataFormatada = ano + "-" + mes;

    return dataFormatada;
  };

  return (
    <>
      <Breadcrumb
        name={`CONSULTA ${obj?.beneficio}`}
        button={[
          {
            name: "Imprimir Consulta",
            colorBtn: "primary",
            click: handlePrintToPDF,
            value: data,
            view: true,
          },
        ]}
      />
      <div ref={componentRefs}>
        <section className="section contact">
          <div className="row gy-4">
            <div className="col-xl-12">
              <div className="row">
                <div style={{ textAlign: "center" }}>
                  <h4>Histórico de</h4>
                  <h3>
                    <b>Empréstimo Consignado</b>
                  </h3>
                  <p
                    style={{
                      width: "100%",
                      padding: "1.5px",
                      backgroundColor: "#ccc",
                    }}
                  ></p>
                  <p>{formatName(obj?.nome) || "Não Informado"}</p>
                </div>
                <div className="info-box card">
                  <h3>Benefício</h3>
                  <br />
                  <div className="row">
                    <div className="col-lg-4">
                      <h9 style={{ color: "blue" }}>
                        <b>
                          {formatName(obj?.especie?.descricao) ||
                            "Não Informado"}
                        </b>
                      </h9>
                      <p>
                        <b>Especie: </b>
                        {obj?.especie?.codigo || "Não Informado"}
                      </p>
                      <p>
                        <b>N° Benefício: </b>
                        {obj?.beneficio || "Não Informado"}
                      </p>
                      <p>
                        <b>CPF: </b> {obj?.cpf || "Não Informado"}
                      </p>
                      <p>
                        <b>Data Nascimento: </b>
                        {obj?.dataNascimento
                          ? isDateValid(obj?.dataNascimento)
                            ? moment(
                                converterParaPadraoAmericano(
                                  obj?.dataNascimento
                                ),
                                "YYYY-MM-DD"
                              ).format("DD/MM/YYYY")
                            : dataFotmatSubsTring(obj?.dataNascimento)
                          : "Não encontrado"}
                      </p>
                      <p>
                        <b>DDB:</b>{" "}
                        {obj?.ddb
                          ? isDateValid(obj?.ddb)
                            ? moment(
                                converterParaPadraoAmericano(obj?.ddb),
                                "YYYY-MM-DD"
                              ).format("DD/MM/YYYY")
                            : dataFotmatSubsTring(obj?.ddb)
                          : "Não encontrado"}
                      </p>
                      <p>
                        <b>DIB:</b>{" "}
                        {obj?.dib
                          ? isDateValid(obj?.dib)
                            ? moment(
                                converterParaPadraoAmericano(obj?.dib),
                                "YYYY-MM-DD"
                              ).format("DD/MM/YYYY")
                            : dataFotmatSubsTring(obj?.dib)
                          : "Não encontrado"}
                      </p>
                      <p>
                        <b>Situação:</b>{" "}
                        {obj?.situacaoBeneficio || "Não Informado"}
                      </p>
                    </div>
                    <div className="col-lg-4">
                      <p>
                        {obj?.possuiRepresentanteLegalProcurador == 0
                          ? "Não possui representante legal"
                          : "Possui representante legal"}
                      </p>
                      <p>
                        <b>Representante Legal:</b>{" "}
                        {obj?.DadosRepresentante?.Nome || "Não Informado"}
                      </p>
                      <p>
                        <b>Representante CPF:</b>{" "}
                        {obj?.DadosRepresentante?.CPF || "Não Informado"}
                      </p>
                      <p>
                        <b>
                          {obj?.BloqueadoEmprestimo === "1"
                            ? "Bloqueado para empréstimo"
                            : "Não Bloqueado para empréstimo"}
                        </b>
                      </p>
                      <p>
                        <b>
                          {obj?.PermiteEmprestimo === "1"
                            ? "Permitido para empréstimo"
                            : "Não permitido para empréstimo"}
                        </b>
                      </p>
                    </div>
                    <div className="col-lg-4">
                      <p>
                        <b>Banco:</b> {obj?.dadosBancarios?.banco?.codigo} -{" "}
                        {obj?.dadosBancarios?.banco?.nome}
                      </p>
                      <p>
                        <b>Agência:</b> {obj?.dadosBancarios?.agencia?.codigo}
                      </p>
                      <p>
                        <b>Tipo:</b> {obj?.dadosBancarios?.banco?.tipo}
                      </p>
                      <p>
                        <b>Numero:</b> {obj?.dadosBancarios?.banco?.numero}
                      </p>
                      <p>
                        <b>COD ASSO:</b> {obj?.cod_assossiacao}
                      </p>
                      <p>
                        <b>NOME ASSO:</b> {obj?.assossiacao || "Não Informado"}
                      </p>
                      <p>
                        <b>VALOR:</b>{" "}
                        {(obj?.cod_assossiacao &&
                          formatarValorEmDinheiro(
                            obj?.margem?.baseCalculoMargemConsignavel * 1.71
                          )) ||
                          "Não Informado"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="info-box card">
                  <h3>Margem para Empréstimo/Cartão e Resumo</h3>
                  <br />
                  <div className="row">
                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <td></td>
                          <td style={{ background: "#ccc" }}>Empréstimos</td>
                          <td style={{ background: "#ccc" }}>RMC</td>
                          <td style={{ background: "#ccc" }}>RCC</td>
                        </tr>
                        <tr>
                          <td style={{ background: "#ccc" }}>
                            Base de Cálculo
                          </td>
                          <td>
                            {formatarValorEmDinheiro(
                              obj?.margem?.baseCalculoMargemConsignavel
                            )}
                          </td>
                          <td>
                            {formatarValorEmDinheiro(
                              obj?.margem?.baseCalculoMargemConsignavel
                            )}
                          </td>
                          <td>
                            {formatarValorEmDinheiro(
                              obj?.margem?.baseCalculoMargemConsignavel
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ background: "#ccc" }}>
                            Margem Consignável
                          </td>
                          <td>
                            {formatarValorEmDinheiro(
                              obj?.margem?.baseCalculoMargemConsignavel * 0.3
                            )}
                          </td>
                          <td>
                            {formatarValorEmDinheiro(
                              obj?.margem?.baseCalculoMargemConsignavel * 0.05
                            )}
                          </td>
                          <td>
                            {formatarValorEmDinheiro(
                              obj?.margem?.baseCalculoMargemConsignavel * 0.05
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ background: "#ccc" }}>
                            Margem Utilizada
                          </td>
                          <td>{formatarValorEmDinheiro(utilizada)}</td>
                          <td>
                            {formatarValorEmDinheiro(
                              cartaoRmc && cartaoRmc.length > 0
                                ? cartaoRmc[0].valorReservado
                                : 0
                            )}
                          </td>
                          <td>
                            {formatarValorEmDinheiro(
                              cartaoRcc && cartaoRcc.length > 0
                                ? cartaoRcc[0].valorReservado
                                : 0
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ background: "#ccc" }}>
                            Margem Reservada
                          </td>
                          <td>R$ 0,00</td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td style={{ background: "#ccc" }}>
                            Margem Disponível
                          </td>
                          <td>
                            {formatarValorEmDinheiro(
                              obj?.margem?.margemDisponivelEmprestimo
                            )}
                          </td>
                          <td>
                            {formatarValorEmDinheiro(
                              obj?.margem?.margemDisponivelCartao
                            )}
                          </td>
                          <td>
                            {formatarValorEmDinheiro(
                              obj?.margem?.margemDisponivelRcc
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ background: "#ccc" }}>
                            Margem Extrapolada
                          </td>
                          <td>{formatarValorEmDinheiro(margemExtrapolada)}</td>
                          <td>R$ 0,00</td>
                          <td>R$ 0,00</td>
                        </tr>
                      </tbody>
                    </table>
                    <div>
                      <p>
                        * A margem consignável atual representa 45% da base de
                        cálculo para empréstimos. Dessa margem, 35% é para
                        empréstimos e 10% para cartão, sendo 5% para RMC e 5%
                        para RCC. ** O valor da margem reservada está incluído
                        no valor da margem utilizada. *** A margem extrapolada
                        representa o valor que excedeu a margem disponível, que
                        pode ocorrer em situações específicas como a redução da
                        renda do benefício.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="info-box card"
                  style={{ pageBreakInside: "avoid" }}
                >
                  <div
                    className="d-none"
                    id="dividerPrint"
                    style={{ height: "3rem" }}
                  >
                    {" "}
                  </div>
                  <h3>Empréstimos Bancários</h3>
                  <br />
                  <div className="row">
                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <td colSpan="9"> Contratos Ativos e Suspensos</td>
                        </tr>
                        <tr>
                          <td>Contrato</td>
                          <td>Banco</td>
                          <td>Data Inclusão</td>
                          <td>Início de Desconto</td>
                          <td>Fim de Desconto</td>
                          <td>Quantidade de Parcelas</td>
                          <td>Taxa CET</td>
                          <td>Parcelas</td>
                          <td>Emprestado</td>
                          <td>Saldo Quitação</td>
                        </tr>
                        {contratos && contratos.length > 0 ? (
                          contratos.map((e) => {
                            let parcelasPagas =
                              e.quantidadeParcelas -
                              e.quantidadeParcelasEmAberto;
                            return (
                              <>
                                <tr style={{ textAlign: "center" }}>
                                  <td>{e?.contrato || "Não encontrado"}</td>
                                  <td>
                                    {e?.banco?.codigo} - {e?.banco?.nome}
                                  </td>
                                  <td>
                                    {e?.dataInclusao
                                      ? isDateValid(e?.dataInclusao)
                                        ? moment(
                                            converterParaPadraoAmericano(
                                              e?.dataInclusao
                                            ),
                                            "MM-DD-YYYY"
                                          ).format("DD/MM/YYYY") ===
                                          "Invalid date"
                                          ? moment(
                                              converterParaPadraoAmericano(
                                                e?.dataInclusao
                                              ),
                                              "YYYY-MM-DD"
                                            ).format("DD/MM/YYYY")
                                          : moment(
                                              converterParaPadraoAmericano(
                                                e?.dataInclusao
                                              ),
                                              "MM-DD-YYYY"
                                            ).format("DD/MM/YYYY")
                                        : dataFotmatSubsTring(e?.dataInclusao)
                                      : "Não encontrado"}
                                  </td>
                                  <td>
                                    {e?.competenciaInicioDesconto
                                      ? isDateValid(
                                          e?.competenciaInicioDesconto
                                        )
                                        ? moment(
                                            converterParaPadraoAmericano(
                                              e?.competenciaInicioDesconto
                                            ),
                                            "MM-DD-YYYY"
                                          ).format("DD/MM/YYYY") ===
                                          "Invalid date"
                                          ? moment(
                                              converterParaPadraoAmericano(
                                                e?.competenciaInicioDesconto
                                              ),
                                              "YYYY-MM-DD"
                                            ).format("DD/MM/YYYY")
                                          : moment(
                                              converterParaPadraoAmericano(
                                                e?.competenciaInicioDesconto
                                              ),
                                              "MM-DD-YYYY"
                                            ).format("DD/MM/YYYY")
                                        : dataFotmatSubsTring(
                                            e?.competenciaInicioDesconto
                                          )
                                      : "Não encontrado"}
                                  </td>
                                  <td>
                                    {e?.competenciaFimDesconto
                                      ? isDateValid(e?.competenciaFimDesconto)
                                        ? moment(
                                            converterParaPadraoAmericano(
                                              e?.competenciaFimDesconto
                                            ),
                                            "MM-DD-YYYY"
                                          ).format("DD/MM/YYYY")
                                        : dataFotmatSubsTring(
                                            e?.competenciaFimDesconto
                                          )
                                      : "Não encontrado"}
                                  </td>
                                  <td>
                                    {parcelasPagas} / {e?.quantidadeParcelas}
                                  </td>
                                  <td>
                                    {e?.taxa
                                      ? parseFloat(e.taxa).toFixed(2)
                                      : "0"}
                                  </td>
                                  <td>
                                    {formatarValorEmDinheiro(e?.valorParcela)}
                                  </td>
                                  <td>
                                    {formatarValorEmDinheiro(
                                      e?.valorEmprestado
                                    )}
                                  </td>
                                  <td>
                                    {formatarValorEmDinheiro(e?.saldoQuitacao)}
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        ) : (
                          <>
                            <tr style={{ textAlign: "center" }}>
                              <td colSpan="7">Não existem dados</td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="info-box card">
                  <h3>Cartão de Crédito</h3>
                  <br />
                  <div className="row">
                    <table className="table table-bordered">
                      <tbody>
                        <tr style={{ textAlign: "center" }}>
                          <td colSpan="7">
                            <b>Cartão de Crédito - RMC</b>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="7"> Contratos Ativos e Suspensos</td>
                        </tr>
                        <tr>
                          <td>Contrato</td>
                          <td>Banco</td>
                          <td>Origem da Averbação</td>
                          <td>Data Inclusão</td>
                          <td>Limite Cartão</td>
                          <td>Reservado</td>
                        </tr>
                        {cartaoRmc && cartaoRmc.length > 0 ? (
                          cartaoRmc.map((e) => {
                            console.log(e);
                            return (
                              <>
                                <tr style={{ textAlign: "center" }}>
                                  <td>{e?.contrato}</td>
                                  <td>
                                    {e?.banco?.codigo} - {e?.banco?.nome}
                                  </td>
                                  <td>Averbação nova</td>
                                  <td>
                                    {e?.dataInicioContrato
                                      ? isDateValid(e?.dataInicioContrato)
                                        ? moment(
                                            converterParaPadraoAmericano(
                                              e?.dataInicioContrato
                                            ),
                                            "YYYY-MM-DD"
                                          ).format("DD/MM/YYYY")
                                        : dataFotmatSubsTring(
                                            e?.dataInicioContrato
                                          )
                                      : "Não encontrado"}
                                  </td>
                                  <td>
                                    {formatarValorEmDinheiro(e?.limiteCartao)}
                                  </td>
                                  <td>
                                    {formatarValorEmDinheiro(e?.valorReservado)}
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        ) : (
                          <>
                            <tr style={{ textAlign: "center" }}>
                              <td colSpan="7">Não existem dados</td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="row">
                    <table className="table table-bordered">
                      <tbody>
                        <tr style={{ textAlign: "center" }}>
                          <td colSpan="7">
                            <b>Cartão de Crédito - RCC</b>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="7"> Contratos Ativos e Suspensos</td>
                        </tr>
                        <tr>
                          <td>Contrato</td>
                          <td>Banco</td>
                          <td>Origem da Averbação</td>
                          <td>Data Inclusão</td>
                          <td>Limite Cartão</td>
                          <td>Reservado</td>
                        </tr>
                        {cartaoRcc && cartaoRcc.length > 0 ? (
                          cartaoRcc.map((e) => {
                            return (
                              <>
                                <tr style={{ textAlign: "center" }}>
                                  <td>{e?.contrato}</td>
                                  <td>
                                    {e?.banco?.codigo} - {e?.banco?.nome}
                                  </td>
                                  <td>Averbação nova</td>
                                  <td>
                                    {e?.dataInicioContrato
                                      ? isDateValid(e?.dataInicioContrato)
                                        ? moment(
                                            converterParaPadraoAmericano(
                                              e?.dataInicioContrato
                                            ),
                                            "YYYY-MM-DD"
                                          ).format("DD/MM/YYYY")
                                        : dataFotmatSubsTring(
                                            e?.dataInicioContrato
                                          )
                                      : "Não encontrado"}
                                  </td>
                                  <td>
                                    {formatarValorEmDinheiro(e?.limiteCartao)}
                                  </td>
                                  <td>
                                    {formatarValorEmDinheiro(e?.valorReservado)}
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        ) : (
                          <>
                            <tr style={{ textAlign: "center" }}>
                              <td colSpan="7">Não existem dados</td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Offline;
