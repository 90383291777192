const Breadcrumb = ({ name, subtitle, button }) => {
    return (
        <>
            <div className="pagetitle" style={{ margin: '30px 0' }}>
                <h1>{name}</h1>
                <nav className="d-flex justify-content-between align-items-center">
                    <div>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a  href="/painel/dash">Home</a></li>
                            {subtitle && (<>
                                <li className="breadcrumb-item">{subtitle}</li>
                            </>)}
                            <li className="breadcrumb-item active primary-emphasis">{name}</li>
                        </ol>
                    </div>

                    <div>
                        {button && button.length > 0 && button.map((r) => {
                            return (<>
                                {r.view && (<>
                                    <button onClick={() => r.click(r.value ? r.value : '')} style={{ marginLeft: '10px' }} className={`btn btn-sm btn-${r.colorBtn}`}>{r.name}</button>
                                </>)}
                            </>)
                        })}
                    </div>
                </nav>
            </div>
        </>
    )
}

export default Breadcrumb