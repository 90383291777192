import Breadcrumb from "../../components_helpers/breadcrumb";
import {
  formatCPF,
  formatDateYYYYMMDD,
  formatarValorEmDinheiro,
  formatRG,
  formatName,
  formatarNumeroTelefone,
  formatarCEP,
  formatarCNPJ,
} from "../../helpers/FormatData";
import LoadingPage from '../../components_helpers/loading'

const Pessoa = (data) => {
  const obj = data.data;
  const cadastrais = obj.cadastrais;
  const contatos = obj.telefones;
  const enderecos = obj.enderecos;
  const emails = obj.emails;
  const obito = obj.obito;
  const pessoasLigadas = obj.pessoas_ligadas;
  const perfilConsumo = obj.perfil_consumo;
  const situacaoCadastral = obj.situacao_cadastral;
  const ultimasEmpresas = obj.ultimas_empresas;
  if (!obj) {
    return <LoadingPage/>
  }

  const handlePrintToPDF = () => {
    const element0 = document.querySelector("header");
    const element1 = document.querySelector(".pagetitle");
    element0.style.setProperty("display", "none", "important");
    element1.style.setProperty("display", "none", "important");

    // Chamar a função de impressão
    window.print();

    element0.style.removeProperty("display");
    element1.style.removeProperty("display");
  };

  return (
    <>
      <Breadcrumb
        name={`Consulta ${formatCPF(cadastrais?.cpf)}`}
        button={[
          {
            name: "Imprimir Consulta",
            colorBtn: "primary",
            click: handlePrintToPDF,
            value: data,
            view: true,
          },
        ]}
      />
      <section className="section contact">
        <div className="row gy-4">
          <div className="col-xl-12">
            <div className="row">
              <div className="col-lg-12">
                <div className="info-box card">
                  {/* <i className="ri-home-5-fill"></i> */}
                  <h3>Dados Cadastrais</h3>
                  <div className="row">
                    <div className="col-lg-6">
                      <p>
                        <b>Nome : </b>
                        {formatName(cadastrais?.nome) || "Indefinido"}
                      </p>
                      <p>
                        <b>CPF : </b>
                        {formatCPF(cadastrais?.cpf) || "Indefinido"}
                      </p>
                      <p>
                        <b>CPF Mãe : </b>
                        {formatCPF(cadastrais?.cpf_mae) || "Indefinido"}
                      </p>
                      <p>
                        <b>Nome Mãe : </b>
                        {formatName(cadastrais?.nome_mae) || "Indefinido"}
                      </p>
                      <p>
                        <b>Nome Pai: </b>
                        {formatName(cadastrais?.nome_pai) || "Indefinido"}
                      </p>
                      <p>
                        <b>Nascimento Mãe : </b>
                        {formatDateYYYYMMDD(cadastrais?.nasc_mae) || "Indefinido"}
                      </p>
                      <p>
                        <b>Sexo : </b>
                        {cadastrais?.sexo}
                      </p>
                      <p>
                        <b>Dívida Ativa : </b>
                        {cadastrais?.divida_ativa_uniao_flag_dau ||
                          "Indefinido"}
                      </p>
                      <p>
                        <b>Estado Cívil : </b>
                        {cadastrais?.estado_civil || "Indefinido"}
                      </p>
                      <p>
                        <b>Fonte de Renda : </b>
                        {cadastrais?.fonte_de_renda || "Indefinido"}
                      </p>
                      <p>
                        <b>Geração : </b>
                        {cadastrais?.geracao || "Indefinido"}
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <p>
                        <b>Idade : </b>
                        {cadastrais?.idade}
                      </p>
                      <p>
                        <b>Nacionalidade : </b>
                        {cadastrais?.nacionalidade || "Indefinido"}
                      </p>
                      <p>
                        <b>Orgão Emissor : </b>
                        {cadastrais?.orgao_emissor || "Indefinido"}
                      </p>
                      <p>
                        <b>Persona Demográfica : </b>
                        {cadastrais?.persona_demografica || "Indefinido"}
                      </p>
                      <p>
                        <b>Escolaridade : </b>
                        {cadastrais?.possivel_escolaridade || "Indefinido"}
                      </p>
                      <p>
                        <b>Possível Profissão : </b>
                        {cadastrais?.possivel_profissao || "Indefinido"}
                      </p>
                      <p>
                        <b>RENDA : </b>
                        {formatarValorEmDinheiro(cadastrais?.renda) ||
                          "Indefinido"}
                      </p>
                      <p>
                        <b>RG : </b>
                        {formatRG(cadastrais?.rg) || "Indefinido"}
                      </p>
                      <p>
                        <b>Score : </b>
                        {cadastrais?.score || "Indefinido"}
                      </p>
                      <p>
                        <b>Signo : </b>
                        {cadastrais?.signo || "Indefinido"}
                      </p>
                      <p>
                        <b>Tempo Empreendedor : </b>
                        {cadastrais?.tempo_empreendedor || "Indefinido"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="info-box card">
                  {/* <i className="bi bi-telephone"></i> */}
                  <h3>Contatos</h3>
                  {contatos && contatos.length > 0 ? (
                    contatos.map((e, index) => {
                      return (
                        <>
                          {index > 0 && (
                            <>
                              <hr />
                            </>
                          )}
                          <p>
                            <b>Tel {index} : </b>
                            {formatarNumeroTelefone(e?.ddd + e?.telefone) ||
                              "Indefinido"}
                          </p>
                          <p>
                            <b>Flag Hot : </b> {e?.flag_hot || "Indefinido"}
                          </p>
                          <p>
                            <b>Flag WhatsApp : </b>{" "}
                            {e?.flag_whatsapp || "Indefinido"}
                          </p>
                          <p>
                            <b>Operadora : </b> {e?.operadora || "Indefinido"}
                          </p>
                          <p>
                            <b>Procon : </b> {e?.procon || "Indefinido"}
                          </p>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <p>Não existem Telefones</p>
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="info-box card">
                  {/* <i className="bi bi-geo-alt"></i> */}
                  <h3>Endereços</h3>
                  {enderecos && enderecos.length > 0 ? (
                    enderecos.map((e, index) => {
                      return (
                        <>
                          {index > 0 && (
                            <>
                              <hr />
                            </>
                          )}
                          <p>
                            <b>Cep {index} : </b>{" "}
                            {formatarCEP(e?.cep) || "Indefinido"}
                          </p>
                          <p>
                            <b>Cidade : </b>{" "}
                            {formatName(e?.cidade) || "Indefinido"}
                          </p>
                          <p>
                            <b>Bairro : </b>{" "}
                            {formatName(e?.bairro) || "Indefinido"}
                          </p>
                          <p>
                            <b>Complemento : </b>{" "}
                            {formatName(e?.complemento) || "Indefinido"}
                          </p>
                          <p>
                            <b>Logradouro : </b>{" "}
                            {formatName(e?.logradouro) || "Indefinido"}
                          </p>
                          <p>
                            <b>Número : </b> {e.numero || "Indefinido"}
                          </p>
                          <p>
                            <b>UF : </b> {e.uf || "Indefinido"}
                          </p>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <p>Não existem Endereços</p>
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="info-box card">
                  {/* <i className="bi bi-geo-alt"></i> */}
                  <h3>Pessoas Ligadas</h3>
                  {pessoasLigadas && pessoasLigadas.length > 0 ? (
                    pessoasLigadas.map((e, index) => {
                      return (
                        <>
                          {index > 0 && (
                            <>
                              <hr />
                            </>
                          )}
                          <p>
                            <b>CPF {index} : </b>{" "}
                            {formatCPF(e?.cpf) || "Indefinido"}
                          </p>
                          <p>
                            <b>Nome : </b> {formatName(e?.nome) || "Indefinido"}
                          </p>
                          <p>
                            <b>Vinculo : </b> {e?.vinculo || "Indefinido"}
                          </p>
                          <p>
                            <b>Nascimento : </b>{" "}
                            {formatDateYYYYMMDD(e?.nasc) || "Indefinido"}
                          </p>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <p>Não existem Endereços</p>
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="info-box card">
                  {/* <i className="bi bi-geo-alt"></i> */}
                  <h3>Perfil de Consumo</h3>
                  {perfilConsumo && perfilConsumo.length > 0 ? (
                    perfilConsumo.map((e, index) => {
                      return (
                        <>
                          {index > 0 && (
                            <>
                              <hr />
                            </>
                          )}
                          <p>
                            <b>Consultado nos útimos 6 meses : </b>
                            {e?.consultado_6meses || "Indefinido"}
                          </p>
                          <p>
                            <b>Consultado nos útimos 12 meses : </b>
                            {e?.consultado_12meses || "Indefinido"}
                          </p>
                          <p>
                            <b>Consumo : </b>
                            {e?.consumo || "Indefinido"}
                          </p>
                          <p>
                            <b>Persona Digital : </b>
                            {e?.persona_digital || "Indefinido"}
                          </p>
                          <p>
                            <b>Possível Aposentado : </b>
                            {e?.possivel_aposentado || "Indefinido"}
                          </p>
                          <p>
                            <b>Propensão de Pagamento : </b>
                            {formatarValorEmDinheiro(e?.propensao_pagamento) ||
                              "Indefinido"}
                          </p>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <p>Não existem Endereços</p>
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="info-box card">
                  {/* <i className="bi bi-geo-alt"></i> */}
                  <h3>Últimas Empresas</h3>
                  {ultimasEmpresas && ultimasEmpresas.length > 0 ? (
                    ultimasEmpresas.map((e, index) => {
                      return (
                        <>
                          {index > 0 && (
                            <>
                              <hr />
                            </>
                          )}
                          <p>
                            <b>CNPJ : </b>
                            {formatarCNPJ(e?.cnpj) || "Indefinido"}
                          </p>
                          <p>
                            <b>Razão Social : </b>{" "}
                            {e?.razao_social || "Indefinido"}
                          </p>
                          <p>
                            <b>Data Desligamento : </b>
                            {e?.data_desligamento || "Indefinido"}
                          </p>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <p>Não existem Endereços</p>
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="info-box card">
                  {/* <i className="bi bi-envelope"></i> */}
                  <h3>Situação Cadastral</h3>
                  {situacaoCadastral && situacaoCadastral.length > 0 ? (
                    situacaoCadastral.map((e, index) => {
                      return (
                        <>
                          {index > 0 && (
                            <>
                              <hr />
                            </>
                          )}
                          <p>
                            <b>Situação : </b>
                            {formatName(e?.descricao) || "Indefinido"}
                          </p>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <p>Não existem Emails</p>
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="info-box card">
                  {/* <i className="bi bi-envelope"></i> */}
                  <h3>Emails</h3>
                  {emails && emails.length > 0 ? (
                    emails.map((e, index) => {
                      return (
                        <>
                          {index > 0 && (
                            <>
                              <hr />
                            </>
                          )}
                          <p>
                            <b>Email {index} : </b> {e?.email || "Indefinido"}
                          </p>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <p>Não existem Emails</p>
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="info-box card">
                  {/* <i className="bi bi-clock"></i> */}
                  <h3>ÓBITO</h3>
                  <p>
                    <b>Situação : </b>
                    {obito[0]?.flag_obito}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Pessoa;
