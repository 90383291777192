const Footer = () => {
  return (
    <>
      <footer id="footer" className="footer">
        <div className="copyright">
          &copy; Copyright <strong>            Top Consulta</strong> - Todos os Direitos
          Reservados
        </div>
        <div className="credits">
          Desenvolvido por{" "}
          <a target="_blakc" href="#">
            Top Consulta
          </a>
        </div>
      </footer>
    </>
  );
};

export default Footer;
