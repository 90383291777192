// biblioteca
import { Result } from "antd";

const ForbiddenPage = () => {
  return (
    <Result
      status="403"
      title="403"
      subTitle="Desculpe, você não está autorizado a acessar esta página."
    />
  );
};
export default ForbiddenPage;
