const Cards = ({ col, name, total, icon }) => {
  return (
    <>
      <div className={col}>
        <div className="card info-card sales-card">
          <div className="card-body">
            <h5
              className="card-title font-weight-bold"
              style={{ fontWeight: "700" }}
            >
              {name}
            </h5>

            <div className="d-flex align-items-center">
              <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                <i className={icon}></i>
              </div>
              <div className="ps-3">
                <h6 style={{ fontWeight: "100" }}>{total}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Cards;
