// react
import { useEffect, useState } from "react";
// componentes
import MessageDisplay from "../messages/messageDisplay";
import sweetAlert from "../messages/sweet";
import Breadcrumb from "../components_helpers/breadcrumb";
// suportes
import { formatName, formatTypeConsult } from "../helpers/FormatData";
import Pagination from "../helpers/Paginate";
// import { formatDate } from "../helpers/FormatData";
import { ApiRequest } from "../helpers/ApiRequest";
import moment from 'moment'

const Arquivos = () => {
  // vars
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [get, setGet] = useState(false);
  const api = new ApiRequest();
  const msg = new MessageDisplay();
  const sweet = new sweetAlert();
  const pageSize = 10;
  // func's
  const getFiles = async () => {
    const result = await api.get(
      `get-arquivos?page=${offset}&pageSize=${pageSize}`,
      false
    );
    if (result.error === true) {
      return msg.error(
        "Arquivos",
        "Desculpe mais não foi possível localizar arquivos"
      );
    } else {
      setData(result.data.consultsResult);
      setTotal(result.data.total);
    }
    setGet(false);
  };

  const generateDownload = async (obj) => {
    if (obj.status !== "finalizado") {
      return sweet.info(
        "<b>Desculpe...</b> para gerar esse arquivo é necessário que ele esteje finalizado",
        6000,
        true
      );
    }
    try {
      await api.get(`get-arquive-data/${obj.id}`).then(async (result) => {
        if (result.error === true) {
          return msg.error(
            "Download",
            "Desculpe mais não foi possível localizar o arquivo"
          );
        }
        await getFiles();
        return msg.success(
          "Sucesso",
          "Seu arquivo está sendo gerado para download"
        );
      });
    } catch (error) {
      return msg.error(
        "Download",
        "Desculpe mais não foi possível localizar o arquivo, tente novamente"
      );
    }
  };

  const downloadExcel = async (obj) => {
    if (obj.link_download) {
      const link = document.createElement("a");
      link.target = "_blank";
      link.href = obj.link_download;
      link.click();
    } else {
      return sweet.info(
        "Desculpe mais não foi possível localizar o arquivo para download verifiquei com a equipe de suporte",
        5000,
        true,
        "Confirmar"
      );
    }
  };

  const handlePage = async (e) => {
    let totalProx = (e - 1) * pageSize;
    setOffset(totalProx);
    setCurrentPage(e);
    setGet(true);
  };

  if (get) {
    getFiles();
  }

  useEffect(() => {
    getFiles();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getFiles();
    }, 15000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      <Breadcrumb name={"Arquivos"} />
      <section className="section">
        <div className="row">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Arquivos</h5>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">id</th>
                    <th scope="col">Documento</th>
                    <th scope="col">Data</th>
                    <th scope="col">Total Enviado</th>
                    <th scope="col">Processado</th>
                    <th scope="col">Total Recebido</th>
                    <th scope="col">Tipo Enriquecimento</th>
                    <th style={{ textAlign: "center" }} scope="col">
                      Download do Arquivo
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.length > 0 &&
                    data.map((e, index) => {
                      const progress = parseInt(
                        (e.total_feito / e.total) * 100
                      );
                      return (
                        <>
                          <tr key={index} style={{ textAlign: "center" }}>
                            <th scope="row">{e?.id}</th>
                            <th scope="row">{e?.name}</th>
                            <td> {e?.date ? moment(e.date).format('DD/MM/YYYY') : null}</td>
                            <td>{e?.total}</td>
                            <td>
                              {e.total_feito}
                              <div className="progress mt-3">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{ width: `${progress}%` }}
                                  aria-valuenow="100"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  {progress}%
                                </div>
                              </div>
                            </td>
                            <td>{e?.total_higienizado}</td>
                            <td>
                              {formatName(formatTypeConsult(e?.type_consult))}
                            </td>
                            <td>
                              {e.status === "finalizado" && (
                                <>
                                  {e.status_download === "iniciado" ? (
                                    <>Gerando Arquivo</>
                                  ) : e.status_download === "finalizado" ? (
                                    <>
                                      <button
                                        onClick={() => downloadExcel(e)}
                                        className="btn btn-sm btn-success"
                                      >
                                        Baixar Arquivo
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        onClick={() => generateDownload(e)}
                                        className="btn btn-sm btn-info"
                                      >
                                        Gerar Arquivo
                                      </button>
                                    </>
                                  )}
                                </>
                              )}
                            </td>
                            {/* <td>{e?.type_consult ? formatTypeConsult(e.type_consult) : null}</td> */}
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
              <Pagination
                total={total}
                currentPage={currentPage}
                onPageChange={(e) => handlePage(e)}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Arquivos;
