import { useEffect, useState } from "react";
import Breadcrumb from "../components_helpers/breadcrumb";
import { ApiRequest } from "../helpers/ApiRequest";
import MessageDisplay from "../messages/messageDisplay";
import { useNavigate } from "react-router-dom";
// import { formatName } from "../helpers/FormatData";
import LoadingPage from "../components_helpers/loading";

const Consultar = () => {
  const api = new ApiRequest();
  const msg = new MessageDisplay();
  const navigate = useNavigate();
  const [data, setData] = useState("");
  const [saldos, setSaldos] = useState("");
  const [inputId, setInputId] = useState("");

  const getTypeConsults = async () => {
    const result = await api.get(`get-dash`);
    if (result.error === true) {
      return msg.error("Consultas", result.data);
    }
    setData(result.data.queries);
    setSaldos(result.data.saldos[0]);
  };

  const disabledInput = (type) => {
    const listInput = document.querySelectorAll("input.inputInfoType");
    const selectTypeInput = document.querySelector(
      `input[data-type='${type}']`
    );
    listInput.forEach((i) => {
      i.disabled = true;
    });
    selectTypeInput.disabled = false;
    selectTypeInput.focus();
  };

  const enableInput = () => {
    const listInput = document.querySelectorAll("input.inputInfoType");
    listInput.forEach((i) => {
      i.disabled = false;
    });
  };

  const formatValueMask = (value, formatType, id) => {
    let input = value.replace(/[^\d]/g, "");
    if (formatType === "cpf" || formatType === "bnf" || formatType === "cnpj") {
      if (input.length > 0) {
        disabledInput(id);
        setInputId(id);
        if (formatType === "cpf" || formatType === "bnf") {
          if (input.length > 3) {
            input = [input.slice(0, 3), ".", input.slice(3)].join("");
          }
          if (input.length > 7) {
            input = [input.slice(0, 7), ".", input.slice(7)].join("");
          }
          if (input.length > 11) {
            input = [input.slice(0, 11), "-", input.slice(11)].join("");
          }
          if (formatType === "bnf") {
            if (input.length > 13) {
              input = input.substr(0, 13);
            }
          }
          if (formatType === "cpf") {
            if (input.length > 14) {
              input = input.substr(0, 14);
            }
          }
        }
        if (formatType === "cnpj") {
          if (input.length > 14) {
            input = input.substr(0, 14);
          }
          if (input.length > 2) {
            input = `${input.slice(0, 2)}.${input.slice(2)}`;
          }
          if (input.length > 6) {
            input = `${input.slice(0, 6)}.${input.slice(6)}`;
          }
          if (input.length > 10) {
            input = `${input.slice(0, 10)}/${input.slice(10)}`;
          }
          if (input.length > 15) {
            input = `${input.slice(0, 15)}-${input.slice(15)}`;
          }
        }
      } else {
        enableInput();
      }
    }

    const valueInput = document.getElementById(`valueinput_${id}`);
    if (valueInput) {
      valueInput.value = input;
    }
  };

  const validadeHours = (item) => {
    let hoursConsult = false;
    if (item) {
      const horaInicio = 8; // Hora de início do expediente
      const horaFim = 18; // Hora de término do expediente

      const horarioAtual = new Date();
      const hora = horarioAtual.getHours();
      const diaSemana = horarioAtual.getDay();

      if (
        diaSemana >= 1 &&
        diaSemana <= 5 &&
        hora >= horaInicio &&
        hora < horaFim
      ) {
        hoursConsult = false; // O horário atual está dentro do expediente
      } else {
        hoursConsult = false; // O horário atual está fora do expediente
      }
    }

    return hoursConsult;
  };

  const consultPost = async (e) => {
    e.preventDefault();
    const result = await api.post(`consulta-fila-rabbit`, e);
    if (result.error === true) {
      disabledInput(inputId);
      return msg.error("Consulta", result.data);
    }
    navigate(`../painel/consultas/`);
  };

  useEffect(() => {
    getTypeConsults();
  }, []);

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <>
      <Breadcrumb name={"Consultar"} />
      <section className="section">
        {/* <div className="row">
          <div className="col-md-12">
            <div style={{ background: '#FFCFC9', padding: '10px', marginBottom: '10px', }}>
              <p className="warning">
                Prezados, as consultas Online estão apresentando instabilidade em todo o Brasil. Ao solicitar uma consulta, por favor, aguarde até que ela seja concluída como <b>FINALIZADA</b> ou retornada com um <b>ERRO</b>. O sistema tentará realizar a consulta até 15 vezes antes de retornar uma mensagem para você.</p>
            </div>
          </div>
        </div> */}
        <div className="row">
          <div className="col-12">
            <div className="row">
              {data &&
                data.map((e, index) => {
                  const hours = validadeHours(e.hours);
                  return (
                    <>
                      {e.status === 1 && (<>
                        <div className="col-xxl-3 col-md-4" key={index}>
                          <div className="card info-card sales-card">
                            <div className="card-body">
                              <div className="d-flex d-flex justify-content-between align-items-center">
                                <h5
                                  style={{ fontSize: "1em", paddingBottom: 0 }}
                                  className="card-title fw-semibold"
                                >
                                  {e.name}

                                  <span>
                                    {e.type === "saldo_in100_plus" && (
                                      <>
                                        <span
                                          className="badge bg-success text-white"
                                          style={{
                                            marginLeft: "10px",
                                            fontSize: "10px",
                                          }}
                                        >
                                          Novidade!
                                        </span>
                                      </>
                                    )}
                                  </span>
                                  {(!saldos[e.type] || saldos[e.type] === 0) && (
                                    <>
                                      <span
                                        className="badge bg-danger text-white"
                                        style={{
                                          marginLeft: "10px",
                                          fontSize: "10px",
                                        }}
                                      >
                                        Recarregue
                                      </span>
                                    </>
                                  )}
                                </h5>
                              </div>
                              <p style={{ fontSize: "0.8em" }}>
                                Saldo: {saldos[e.type]}
                              </p>
                              {!saldos[e.type] || saldos[e.type] === 0 ? (
                                <>
                                  <span
                                    title="Você não tem saldo para essa consulta"
                                    className="text-danger"
                                    style={{
                                      fontSize: "11px",
                                      position: "absolute",
                                      top: "38px",
                                    }}
                                  >
                                    Solicite agora a sua recarga e aproveite essa
                                    consulta
                                  </span>
                                </>
                              ) : (
                                ""
                              )}

                              <div className="d-flex align-items-center">
                                {hours ? (
                                  <>
                                    <p>
                                      Desculpe, essa consulta está fora do horário
                                      permitido
                                    </p>
                                  </>
                                ) : (
                                  <>
                                    <div className="w-100">
                                      <form
                                        onSubmit={(e) => consultPost(e)}
                                        className="w-100"
                                      >
                                        <div className="d-flex d-flex justify-content-between align-items-center w-100">
                                          <input
                                            type="hidden"
                                            defaultValue={e.type}
                                            name="typeConsult"
                                          />
                                          <div className="w-100">
                                            <input
                                              data-type={e.id}
                                              onChange={(event) =>
                                                formatValueMask(
                                                  event.target.value,
                                                  e.format_type,
                                                  e.id
                                                )
                                              }
                                              className="form-control border-info inputInfoType"
                                              id={`valueinput_${e.id}`}
                                              name="document"
                                              placeholder={
                                                e.format_type === "cpf"
                                                  ? "CPF"
                                                  : e.format_type === "bnf"
                                                    ? "Benefício"
                                                    : "CNPJ"
                                              }
                                            />
                                          </div>
                                          <div>
                                            <button
                                              type="submit"
                                              style={{
                                                marginLeft: "10px",
                                                fontSize: "1em",
                                              }}
                                              className="btn btn-sm btn-primary text-white"
                                            >
                                              Pesquisar
                                            </button>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>)}
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Consultar;
