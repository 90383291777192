// componenentes
import Breadcrumb from "../../components_helpers/breadcrumb";

const ErrorConsult = () => {
  return (
    <>
      <Breadcrumb name={"Errro Consulta"} />
      <section className="section contact">
        <div className="row gy-4">
          <div className="col-xl-12">
            <div className="row"></div>
            <h4>Desculpe, mas não foi possível localizar sua consulta.</h4>
          </div>
        </div>
      </section>
    </>
  );
};

export default ErrorConsult;
