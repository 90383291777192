import { useParams } from "react-router-dom";
import Breadcrumb from "../components_helpers/breadcrumb";
import { ApiRequest } from "../helpers/ApiRequest";
import { Modal } from "antd";
import MessageDisplay from "../messages/messageDisplay";
import { useEffect, useState } from "react";
// import { formatDate } from "../helpers/FormatData";
import moment from 'moment'
import LoadingPage from '../components_helpers/loading'

const HistoryCredit = () => {
  const { id } = useParams();
  const api = new ApiRequest();
  const msg = new MessageDisplay();
  const auth = JSON.parse(localStorage.getItem("user"));

  const [credits, setCredit] = useState("");
  const [credito, setCredito] = useState("");
  const [queries, setQueries] = useState("");
  const [saldoConsult, setSaldoConsult] = useState("");
  const [type, setType] = useState("");
  const [show, setShow] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [listCredit, setListCredit] = useState("");
  const [textTotalSaldo, setTextTotalSaldo] = useState("");
  const [select, setSelect] = useState(false);
  const [view, setView] = useState(false);
  const [saldoTotalType, setSaldoTotalType] = useState("");
  const [nameType, setNameType] = useState("");
  const [keyType, setKeyType] = useState("");

  const getDash = async () => {
    const result = await api.get(`get-dash`);
    if (result.error === true) {
      return msg.error(
        "Usuário",
        "Desculpe mais não foi possível localizar o saldo"
      );
    }

    const keys = Object.keys(result.data.saldos[0]);
    const arr = [];

    for (const credit of keys) {
      for (const t of result.data.queries) {
        if (t.type === credit) {
          const obj = {
            name: t.name,
            type: t.type,
            saldo: result.data.saldos[0][credit],
          };
          arr.push(obj);
        }
      }
    }

    setListCredit(result.data.queries);
    setTextTotalSaldo(arr);
  };

  const getSaldoAndCredit = async () => {
    const result = await api.get(`get-saldo-and-credt/${id}/${auth.id}`);
    if (result.error === true) {
      return msg.error("Usuário", result.data);
    }
    setCredit(result.data.credits);
    setQueries(result.data.queries);
    setSaldoConsult(result.data.saldoResult[0]);
    setView(true);
  };

  const creditSave = async (e) => {
    e.preventDefault();
    const dataType = e.target.querySelector("[data-type]").dataset.type;

    if (
      !credito ||
      credito.length === 0 ||
      parseInt(credito) === 0 ||
      isNaN(credito)
    ) {
      return msg.error("Crédito", "Por favor, informe a quantidade de crédito");
    }

    if (!keyType) {
      return msg.error("Crédito", "Selecione uma opção");
    }

    const postData = {
      credit: credito,
      type: keyType,
      userId: id,
      user: auth,
      typeInsert: dataType,
    };
    const result = await api.post(`credit/insert`, postData, false);
    if (result.error === true) {
      return msg.error(
        "Crédito",
        "Desculpe mais não foi possível inserir seus crédito"
      );
    }
    setShow(false);
    await getSaldoAndCredit();
  };

  const getModal = async (e) => {
    setType(e);
    const title = e === "insert" ? "Inserir Crédito" : "Remover Crédito";
    setTitleModal(title);
    await getDash();
    setSelect(false);
    setShow(true);
  };

  const changeSelect = (e) => {
    const typeSelect = e.target.value;

    if (e.target.value) {
      for (const e of textTotalSaldo) {
        if (e.type === typeSelect) {
          setSaldoTotalType(e.saldo);
          setNameType(e.name);
          setKeyType(e.type);
        }
      }
      setSelect(true);
    } else {
      setSelect(false);
    }
  };

  const closeModal = () => {
    setShow(false);
  };

  const numberFormatTotal = (e) => {
    let numeroLimpo = e.target.value.replace(/\D/g, "");
    if (numeroLimpo === "0" || numeroLimpo === 0) {
      return setCredito(1);
    } else {
      return setCredito(numeroLimpo);
    }
  };

  if (select === false) {
    const selectCredit = document.querySelector("select#selectCredit");
    if (selectCredit) {
      selectCredit.value = "";
    }
  }

  useEffect(() => {
    getSaldoAndCredit();
  }, []);

  if (!view) {
    return <LoadingPage />;
  }

  return (
    <>
      <Breadcrumb
        name={"Histórico de Crédito"}
        button={[
          {
            name: "Inserir Crédito",
            colorBtn: "primary",
            click: getModal,
            value: "insert",
            view: true,
          },
          {
            name: "Remover Crédito",
            colorBtn: "danger",
            click: getModal,
            value: "remove",
            view: true,
          },
        ]}
      />
      <section className="section">
        <div className="row">
          {queries &&
            queries.map((e, index) => (<>
              {
                e.status === 1 && (<>
                  <div className="col-xxl-3 col-md-4" key={index}>
                    <div className="card info-card sales-card">
                      <div className="card-body">
                        <h5 style={{ fontSize: "0.8em" }} className="card-title">
                          {e.name} <span></span>
                        </h5>
                        <div className="d-flex align-items-center">
                          <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i className="bi bi-cart"></i>
                          </div>
                          <div className="ps-3">
                            <h6>
                              {saldoConsult[e.type] > 0 ? saldoConsult[e.type] : 0}
                            </h6>
                            {/* <span className="text-success small pt-1 fw-bold">12%</span> <span className="text-muted small pt-2 ps-1">increase</span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>)
              }
            </>
            ))}
        </div>
        <div className="row">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Consultas</h5>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Crédito</th>
                    <th scope="col">Data</th>
                    <th scope="col">Tipo</th>
                    <th scope="col">status</th>
                  </tr>
                </thead>
                <tbody>
                  {credits &&
                    credits.length > 0 &&
                    credits.map((e, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <th scope="row">{e?.credito}</th>
                            <td>{e?.date ? moment(e.date).format('DD/MM/YYYY') : null}</td>
                            <td>{e?.type}</td>
                            <td>
                              <span
                                className={`btn btn-sm btn-${e?.status === "remove" ? "danger" : "success"
                                  }`}
                              >
                                {e?.status === "remove"
                                  ? "Removido"
                                  : "Inserido"}
                              </span>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      <Modal title={titleModal} open={show} onCancel={closeModal} footer={null}>
        {select && (
          <>
            {saldoTotalType && (
              <>
                <p>
                  {" "}
                  SALDO TOTAL CLIENTE{" "}
                  <span className="text-info">
                    {nameType}:{" "}
                    {saldoConsult[keyType] ? saldoConsult[keyType] : 0}
                  </span>
                </p>
                <p>
                  {" "}
                  <label>
                    SEU SALDO TOTAL{" "}
                    <span className="text-info">
                      {nameType}: {saldoTotalType}
                    </span>
                  </label>
                </p>
              </>
            )}
          </>
        )}
        <form onSubmit={(e) => creditSave(e)} className="row g-3">
          {select && (
            <>
              <div className="col-12">
                <label htmlFor="inputNanme4" className="form-label">
                  Crédito
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Saldo"
                  defaultValue={credito}
                  onChange={(e) => {
                    numberFormatTotal(e);
                  }}
                  name="saldo"
                />
              </div>
            </>
          )}
          <div className="col-12">
            <label htmlFor="inputNanme4" className="form-label">
              Tipo de Consulta
            </label>
            <select
              className="form-control"
              id="selectCredit"
              onChange={(e) => {
                changeSelect(e);
              }}
            >
              <option value="" selected="selected">
                {" "}
                Selecione o Crédito{" "}
              </option>
              {listCredit &&
                listCredit.map((e, index) => (<>
                  {e.status === 1 && (<>
                    <option key={index} value={e.type}>
                      {e.name}
                    </option>
                  </>)}
                </>))}
            </select>
          </div>
          {select && (
            <>
              <div className="text-center">
                <button
                  type="submit"
                  data-type={type}
                  className="btn btn-sm btn-success"
                >
                  {titleModal}
                </button>
              </div>
            </>
          )}
        </form>
      </Modal>
    </>
  );
};

export default HistoryCredit;
