import { useEffect, useState } from "react";
import Breadcrumb from "../components_helpers/breadcrumb";
import { useParams } from "react-router-dom";
import { Modal } from "antd";
import { ApiRequest } from "../helpers/ApiRequest";
import MessageDisplay from "../messages/messageDisplay";
import Pagination from "../helpers/Paginate";
import Cards from "../helpers/Cards";

const UsersRelated = () => {
  const { id } = useParams();

  const api = new ApiRequest();
  const msg = new MessageDisplay();
  const pageSize = 10;
  // const auth = JSON.parse(localStorage.getItem('user'))

  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  // const [pageSize, setPageSize] = useState(10)
  const [offset, setOffset] = useState(0);
  const [get, setGet] = useState(false);

  const getUsers = async () => {
    setGet(false);
    const result = await api.get(
      `user/listUser/${id}?page=${offset}&pageSize=${pageSize}`
    );
    if (result.error === true) {
      return msg.error("Usuários", result.data);
    }
    setTotal(result.data.total);
    setData(result.data.users);
  };

  const handlePage = async (e) => {
    let totalProx = (e - 1) * 10;
    setOffset(totalProx);
    setCurrentPage(e);
    setGet(true);
  };

  const searchUser = async (e) => {
    const result = await api.post(`search-users`, e);
    if (result.error === true) {
      return msg.error("Pesquisa", result.data);
    }

    if (result.data.length <= 0) {
      return msg.error(
        "Pesquisa",
        "Não foram encontrados resultados para sua pesquisa"
      );
    }

    setShow(false);
    setData(result.data);
  };

  if (get) {
    getUsers();
  }

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <Breadcrumb name={"Usuários Relácionados"} />
      <section className="section dashboard">
        <div className="row">
          <Cards
            col={"col-4"}
            icon={"bi bi-people"}
            name={"Total de Usuários"}
            total={total}
          />
        </div>
        <div className="row">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Usuários Relacionados</h5>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">E-mail</th>
                    <th scope="col">Level</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.length > 0 &&
                    data.map((e, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <th scope="row">{e.name}</th>
                            <td>{e?.email}</td>
                            <td>{e?.level}</td>
                            <td>{e?.status}</td>
                            {/* <td>{e?.type_consult ? e.type_consult : null}</td> */}
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
              <Pagination
                total={total}
                currentPage={currentPage}
                onPageChange={(e) => handlePage(e)}
              />
            </div>
          </div>
        </div>
      </section>

      <Modal
        title={"Pesquisar Usuário"}
        open={show}
        onCancel={() => setShow(false)}
        footer={null}
      >
        <form onSubmit={(e) => searchUser(e)} className="row g-3">
          <div className="col-12">
            <label htmlFor="inputNanme4" className="form-label">
              Crédito
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Digite Nome / E-mail"
              name="search"
            />
          </div>
          <div className="text-center">
            <button type="submit" className="btn btn-sm btn-info">
              Pesquisar Usuário
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default UsersRelated;
