/**
 * Remove todos os espaços
 * @param {textinput} - 
 * @returns {boolean}
 */
export const removeExtraSpace = (textinput) => {
  if (typeof textinput !== 'string') {
      return '';
  }
  const remove = textinput.replace(/\s+/g, ' ').trim();
  return remove;
}

/**
 * Verifica se possui o nome e sobrenome, iniciando sempre com letra maiuscula. exemplo: Joao Silva
 * @param {textinput} - 
 * @returns {boolean}
 */
export const nameAndSurname = (textinput) => {
  const nameRegEx = /^[A-ZÀ-Ú][a-zà-ú]*((\s|-)[A-ZÀ-Ú][a-zà-ú]*)*$/;
  return nameRegEx.test(textinput)
}

/**
 * Verifica se possui um email valido, exemplo: email@email.com
 * @param {textinput} - 
 * @returns {boolean}
 */
export const checkEmail = (textinput) => {
  const emailRegEx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegEx.test(textinput)
}

/**
 * Conta as palavras, onde o textInput é o texto passado e o maxWordLength é o maximo de palavras permitidas
 * @param {textinput} - 
 * @returns {boolean}
 */
export const countWords = (textinput, maxWordLength) => {
  const removeSpace = removeExtraSpace(textinput);
  if (typeof removeSpace !== 'string') {
      return false
  }
  const words = removeSpace.split(/\s+/).filter(word => word !== '');

  for (const word of words) {
      if (word.length > maxWordLength) {
          return false;
      }
  }

  return words.length;
}

export const checkPassword = (textinput) => {
  const regEx = /^(?=.*[A-Z])(?=.*\d).+$/;

  return !regEx.test(textinput);
}

