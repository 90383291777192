export const menu = [
  {
    title: "Dashboard",
    icon: "bi bi-grid",
    href: "/painel/dash",
    role: ["master", "revenda", "usuario"],
  },
  {
    title: "Consultas",
    icon: "bi bi-journal-text",
    role: ["master", "revenda", "usuario"],
    subMenu: [
      {
        title: "Listar Consultas manuais",
        href: "/painel/consultas",
        role: ["master", "revenda", "usuario"],
      },
      {
        title: "Listar Consultas de API",
        href: "/painel/consultas_api",
        role: ["master", "revenda", "usuario"],
      },
      {
        title: "Consultar",
        href: "/painel/consultar",
        role: ["master", "revenda", "usuario"],
      },
    ],
  },
  {
    title: "Enriquecimento",
    icon: "bi bi-layout-text-window-reverse",
    role: ["master", "revenda", "usuario"],
    subMenu: [
      {
        title: "Listar Arquivos",
        href: "/painel/enriquecimento",
        role: ["master", "revenda", "usuario"],
      },
      {
        title: "Novo Arquivo",
        href: "/painel/enriquecimento/novo",
        role: ["master", "revenda", "usuario"],
      },
    ],
  },
  {
    title: "Usuários",
    icon: "bi bi-person",
    menuKey: "dashboard",
    role: ["master", "revenda"],
    subMenu: [
      {
        title: "Listar Usuários",
        href: "/painel/usuarios",
        role: ["master", "revenda", "usuario"],
      },
      {
        title: "Cadastrar Novo",
        href: "/painel/cadastrar/usuario",
        role: ["master", "revenda", "usuario"],
      },
    ],
  },
  {
    title: "Api",
    icon: "bi bi-gem",
    href: "/painel/api",
    role: ["revenda", "usuario"],
  },
  {
    title: "WhatsApp",
    icon: "bi bi-whatsapp",
    href: "/painel/whatsapp",
    role: ["master", "revenda"],
  },
];
